import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'aqua',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '1px 1px #eee',
    fontSize: 12,
  },
  arrow: {
    color: 'aqua',
  },
}))(Tooltip);

const useStyles1 = makeStyles(() => ({
  root: {
    flexShrink: 0,
    marginLeft: '7px',
  },
}));

//첫페이지나 마지막 페이지로 이동하는 버튼
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
        fontSize='10px'
        style={{ color: '#e79602' }}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
        fontSize='10px'
        style={{ color: '#e79602' }}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
        fontSize='10px'
        style={{ color: '#e79602' }}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
        fontSize='10px'
        style={{ color: '#e79602' }}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    //border: '1px solid white',
    backgroundColor: '#202020',
    borderRaidus: 0,
    width: '98%',
    margin: 'auto',
    padding: 0,
  },
  check: {
    color: '#F17300',
  },
  list_name: {
    width: '30px',
    textOverflow: 'ellipsis',
  },
});

export default function CodeTable(props) {
  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(3);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowsPerPage.length - page * rowsPerPage);

  useEffect(() => {
    setPage(parseInt(props.problemNumber / rowsPerPage));
  }, [props.secId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <TableContainer component={Paper} style={{ backgroundColor: '#252526' }}>
      <Table className={classes.table} size={'small'} aria-label='custom pagination table'>
        <TableBody>
          {(rowsPerPage > 0
            ? props.lists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : props.lists
          )?.map((row, i) => (
            <TableRow key={row.scodeId}>
              <TableCell
                style={{
                  height: '45px',
                  borderBottom: '1px solid rgba(255,255,255,0.1)',
                  padding: '5px 10px 5px 10px',
                }}
                component='th'
                scope='row'
              >
                <LightTooltip title='문제를 선택하세요.' placement='right' arrow>
                  <Button
                    value={[row.scodeId, i + 1]}
                    number={i + 1 + page * rowsPerPage}
                    onClick={props.handleSelectProblem}
                  >
                    <span
                      style={{
                        color: 'aqua',
                        fontSize: '15px',
                        textAlign: 'left',
                        width: '325px',
                      }}
                    >
                      {row.level}_{row.scodeVulFile}
                    </span>
                  </Button>
                </LightTooltip>
              </TableCell>
            </TableRow>
          ))}

          {props.lists.length === 0 && (
            <TableRow style={{ height: `${53 * emptyRows}px` }}>
              <TableCell
                style={{
                  height: '45px',
                  borderBottom: '1px solid rgba(255,255,255,0.1)',
                  padding: '5px 10px 5px 10px',
                }}
                component='th'
                scope='row'
              >
                <p style={{ fontSize: '15px', textAlign: 'left', width: '325px', color: '#e79602' }}>
                  출제된 문제가 없습니다.
                </p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={1}
              count={props.lists.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
              style={{
                color: '#e79602',
                fontSize: '13px',
                borderBottom: '1px solid rgba(255,255,255,0.1)',
                paddingRight: '40px',
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
