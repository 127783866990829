import React, { useState, useRef, Fragment } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import * as XLSX from 'xlsx';
import styled from 'styled-components';

import { useRedirectWithoutOperator } from 'hooks/redirect.hook';
import { useFetchPersonalResultInfo, useFetchUserGroupInfo } from 'hooks';
import { EXAM_ORDER, LANGUAGE_ID } from 'constants/common';
import { Header } from '../../../widgets';
import styles from '../../Mypage/Mypage.module.scss';
import '../../MyPage.css';

const LearningResult = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { groupId, userId, languageId } = state || {};

  const ref = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [codeScore] = useState([]);
  const [courseData] = useState([]);

  const sidebar = 'left';
  const textList = ['없음', '미흡', '보통', '우수'];

  useRedirectWithoutOperator();

  const printFunc = () => {
    // 보안약점 진단훈련 + 페이지네이션 숨기고 출력용 보안약점 진단훈련 결과 표시
    document.getElementById('codeScore').style.display = 'none';
    document.getElementById('paginagion').style.display = 'none';
    document.getElementById('codeScoreForPrint').style.display = 'block';

    const html = document.querySelector('html');
    // 출력할 내용 가져오기
    const printContents = document.querySelector('#content').innerHTML;

    const printDiv = document.createElement('DIV');
    printDiv.className = 'print-div';

    html.appendChild(printDiv);

    // 출력할 내용 인쇄 화면 속 태그에 넣어주기
    printDiv.innerHTML = printContents;
    document.body.style.display = 'none';

    window.print();
    document.body.style.display = 'block';
    printDiv.style.display = 'none';

    //출력용 보안약점 진단훈련 결과 다시 숨기고 보안약점 진단훈련 + 페이지네이션 원상복귀
    document.getElementById('codeScore').style.display = 'block';
    document.getElementById('paginagion').style.display = 'block';
    document.getElementById('codeScoreForPrint').style.display = 'none';
  };

  const tableToExcel = () => {
    // work_book 생성
    const wb = XLSX.utils.book_new();
    // 강의 학습, 보안약점 진단훈련 work_sheet 생성
    const ws1 = XLSX.utils.table_to_sheet(document.getElementById('courseData'), { raw: true });
    const ws2 = XLSX.utils.table_to_sheet(document.getElementById('codeScoreForPrint'), { raw: true });

    // work_book에 work_sheet 추가
    XLSX.utils.book_append_sheet(wb, ws1, '1. 강의 학습');
    XLSX.utils.book_append_sheet(wb, ws2, '2. 보안약점 진단훈련');

    // work_sheet 컬럼(순서대로) 너비 설정
    ws1['!cols'].push({ width: 20 }, { width: 30 });
    ws2['!cols'].push({ width: 30 }, { width: 30 });

    XLSX.writeFile(wb, `SECOLAB_${userGroupInfo?.userName}_report.xlsx`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const GoBack = () => {
    history.goBack();
  };

  const StyledTableCell = withStyles(() => ({
    head: {
      backgroundColor: '#a8a9a9',
      color: '#fff',
    },
    body: {
      fontSize: 16,
    },
  }))(TableCell);

  /* 유저가 속한 그룹 정보 및 개인 정보 조회 */
  const { data: userGroupInfo } = useFetchUserGroupInfo({ userId, groupId });

  /* 학생 시험 응시 상세 결과 조회 */
  const { data: personalExamInfo } = useFetchPersonalResultInfo({ userId, languageId });
  const { examResultInfos } = personalExamInfo || {};

  return (
    <>
      <Header>결과 보고서 - {userGroupInfo?.userName}</Header>

      <div id='content'>
        <div className={styles.mypage} ref={ref}>
          <section className={`${styles.wrapper} ${styles.with_sidebar} ${styles.left}`}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableHead>
                  <StyledTableRow className='header'>
                    <StyledTd>아이디</StyledTd>
                    <StyledTd>이름</StyledTd>
                    <StyledTd>학습언어</StyledTd>
                    <StyledTd>학습기간</StyledTd>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTd align='center'>{userGroupInfo?.userId}</StyledTd>
                    <StyledTd align='center'>{userGroupInfo?.userName}</StyledTd>
                    <StyledTd align='center'>{userGroupInfo?.allowLanguages}</StyledTd>
                    <StyledTd align='center'>
                      {moment(userGroupInfo?.groupStartDate).format('YYYY-MM-DD')} ~
                      {userGroupInfo?.groupDueDate ? moment(userGroupInfo?.groupDueDate).format('YYYY-MM-DD') : '현재'}
                    </StyledTd>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </section>

          <br />
          <br />
          <section className={`${styles.wrapper} ${styles.with_sidebar} ${styles.left}`}>
            <TableContainer component={Paper}>
              <h2>1. 역량 평가</h2>
              <Table id='courseData' size='small'>
                <TableHead rowcount={courseData.length}>
                  <StyledTableRow className='header'>
                    <StyledTd align='center'>언어</StyledTd>
                    <StyledTd>차수</StyledTd>
                    <StyledTd>응시일자</StyledTd>
                    <StyledTd>전체 평균 점수</StyledTd>
                    <StyledTd>점수</StyledTd>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {(examResultInfos?.length > 0 && examResultInfos[0].examOrder) !== 1 && (
                    <Fragment>
                      <StyledTableRow>
                        <StyledTd>{LANGUAGE_ID[languageId].toUpperCase()}</StyledTd>
                        <StyledTd>사전 평가</StyledTd>
                        <StyledTd>-</StyledTd>
                        <StyledTd>-</StyledTd>
                        <StyledTd>미응시</StyledTd>
                      </StyledTableRow>
                    </Fragment>
                  )}
                  {examResultInfos?.map((info, index) => {
                    return (
                      <Fragment key={index}>
                        <StyledTableRow>
                          <StyledTd>{LANGUAGE_ID[languageId].toUpperCase()}</StyledTd>
                          <StyledTd>{`${EXAM_ORDER[info.examOrder]} 평가`}</StyledTd>
                          <StyledTd>
                            {info.submitDateTime ? moment(info.submitDateTime).format('YYYY-MM-DD') : '-'}
                          </StyledTd>
                          <StyledTd>{info.groupAvgScore ? `${Math.round(info.groupAvgScore)}점` : '-'}</StyledTd>
                          <StyledTd>
                            {!(info.submitDateTime && info.userScore) ? '미응시' : info.userScore + '점'}
                          </StyledTd>
                        </StyledTableRow>
                      </Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </section>

          <br />
          <br />

          <section className={`${styles.wrapper} ${styles.with_sidebar} ${styles.left}`}>
            <TableContainer component={Paper}>
              <h2>2. 보안약점 진단훈련</h2>
              <Table id='codeScore' size='medium'>
                <TableHead rowcount={codeScore.length}>
                  <StyledTableRow className='header'>
                    <StyledTd rowSpan='2'>보안약점유형</StyledTd>
                    <StyledTd width='20%' rowSpan='2'>
                      보안문제명
                    </StyledTd>
                    <StyledTd width='10%' padding='none' rowSpan='2'>
                      문제언어
                    </StyledTd>
                    <StyledTd width='20%' colSpan='2'>
                      1단계
                    </StyledTd>
                    <StyledTd width='20%' colSpan='2'>
                      2단계
                    </StyledTd>
                    <StyledTd width='10%' padding='none' rowSpan='2'>
                      최종결과
                    </StyledTd>
                  </StyledTableRow>
                  <StyledTableRow className='header'>
                    <StyledTd width='10%' padding='none'>
                      풀이시간
                    </StyledTd>
                    <StyledTd width='10%' padding='none'>
                      훈련결과
                    </StyledTd>
                    <StyledTd width='10%' padding='none'>
                      풀이시간
                    </StyledTd>
                    <StyledTd width='10%' padding='none'>
                      훈련결과
                    </StyledTd>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {codeScore.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((solved, index) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell align='center'>
                          <span className='secotext'>{solved.scategoryName}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          <span className='secotext'>{solved.scodeVulFileName}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          <span className='secotext'>{solved.langId}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center' padding='none'>
                          <span className='secotext'>{solved.elapsedTimeFirst}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          <span className='secotext'>{textList[solved.firstTimeResult]}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center' padding='none'>
                          <span className='secotext'>{solved.elapsedTimeSecond}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          <span className='secotext'>{textList[solved.secondTimeResult]}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          <span className='secotext'>{textList[solved.avgResult]}</span>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Table id='codeScoreForPrint' size='medium' style={{ display: 'none' }}>
                <TableHead rowcount={codeScore.length}>
                  <TableRow>
                    <StyledTableCell align='center' width='20%' rowSpan='2'>
                      <span className='secotext'>보안약점유형</span>
                    </StyledTableCell>
                    <StyledTableCell align='center' width='20%' rowSpan='2'>
                      <span className='secotext'>보안문제명</span>
                    </StyledTableCell>
                    <StyledTableCell align='center' width='10%' padding='none' rowSpan='2'>
                      <span className='secotext'>문제언어</span>
                    </StyledTableCell>
                    <StyledTableCell align='center' width='20%' colSpan='2'>
                      <span className='secotext'>1단계</span>
                    </StyledTableCell>
                    <StyledTableCell align='center' width='20%' colSpan='2'>
                      <span className='secotext'>2단계</span>
                    </StyledTableCell>
                    <StyledTableCell align='center' width='10%' padding='none' rowSpan='2'>
                      <span className='secotext'>최종결과</span>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align='center' width='10%' padding='none'>
                      <span className='secotext'>풀이시간</span>
                    </StyledTableCell>
                    <StyledTableCell align='center' width='10%' padding='none'>
                      <span className='secotext'>훈련결과</span>
                    </StyledTableCell>
                    <StyledTableCell align='center' width='10%' padding='none'>
                      <span className='secotext'>풀이시간</span>
                    </StyledTableCell>
                    <StyledTableCell align='center' width='10%' padding='none'>
                      <span className='secotext'>훈련결과</span>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {codeScore?.map((solved, index) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell align='center'>
                          <span className='secotext'>{solved.scategoryName}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          <span className='secotext'>{solved.scodeVulFileName}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          <span className='secotext'>{solved.langId}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center' padding='none'>
                          <span className='secotext'>{solved.elapsedTimeFirst}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          <span className='secotext'>{textList[solved.firstTimeResult]}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center' padding='none'>
                          <span className='secotext'>{solved.elapsedTimeSecond}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          <span className='secotext'>{textList[solved.secondTimeResult]}</span>
                        </StyledTableCell>
                        <StyledTableCell align='center'>
                          <span className='secotext'>{textList[solved.avgResult]}</span>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                id='paginagion'
                style={{}}
                rowsPerPageOptions={[10, 25]}
                component='div'
                count={codeScore.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </section>
        </div>
      </div>

      <div className='d-flex justify-content-center'>
        <button onClick={GoBack} className='mx-sm-2 btn btn-primary secotext'>
          뒤로가기
        </button>
        <button onClick={printFunc} className='mx-sm-2 btn btn-success secotext'>
          <i className='fas fa-print'></i> 인쇄하기
        </button>
        <button onClick={tableToExcel} className='mx-sm-2 btn btn-success secotext'>
          <i className='far fa-file-excel'></i> Excel 다운로드
        </button>
      </div>
      <br />
    </>
  );
};

const StyledTableRow = styled(TableRow)`
  text-align: center;

  &.header {
    background-color: #2e94d2;
    color: #fff;
    font-weight: bold;
  }
`;

const StyledTd = styled.td`
  padding: 20px;
  font-size: 1.6rem;
`;
export default withRouter(LearningResult);
