import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';
import { Card, CardActions, CardContent, IconButton, Typography, Grid, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import moment from 'moment';

import Alert from '../../../../components/Alert';
import { api } from 'api/config';
import { AuthAtom } from 'state';
import { errorToast } from 'utilities/toast';
import nodejs from './images/nodejs.png';
import spring from './images/spring.png';
import django from './images/django.png';

const useStyles = makeStyles(() => ({
  root: {
    width: 280,
    height: 280,
    border: 'thin dotted aqua',
    textAlign: 'center',
    position: 'relative',
    '&:hover': {
      //backgroundColor: 'aqua',
    },
  },
  projectImage: {
    width: '130px',
    height: 'auto',
  },
}));

function getURLImageProject(type) {
  var result;
  switch (type) {
    case 'node':
      result = nodejs;
      break;
    case 'java':
      result = spring;
      break;
    case 'python':
      result = django;
      break;
    default:
      result = '';
      break;
  }
  return result;
}

export default function ProjectContainer(props) {
  const classes = useStyles();
  const { userId } = useRecoilValue(AuthAtom);
  const [project, setProject] = useState(props.project);

  const handleClickViewProject = (projectPath, projectId) => {
    const container = props.container;

    // 컨테이너 중지되어 있을때 시작하는지 확인
    if (container.state && container.project_id === projectId) {
      api
        .get(`/users/editor/${projectId}`)
        .then((response) => {
          const { data } = response;
          if (data !== null) {
            window.open(`${process.env.REACT_APP_HTTP_IP}:${data.vscode_port}/?folder=/home/coder/projects/`, '_blank');
          } else {
            errorToast('에디터 서버 접근 실패합니다. 다시 확인해주세요');
          }
        })
        .catch((error) => {});
    } else {
      Alert({
        title: '컨테이너를 새로 시작 하시겠습니까??',
        handleClickAccept: () => {
          //accept
          const url = `${process.env.REACT_APP_HTTP_IP}:10000/users/containers`;
          api
            .post(url, {
              projectId,
            })
            .then((response) => {
              const { data } = response;
              if (data === 'success') {
                props.handleUpdateContainer();
              } else {
                errorToast('컨테이너 재시작 오류 발생하니 다시 시도 해주세요.');
              }
            })
            .catch((error) => {
              errorToast('컨테이너 재시작 오류 발생하니 다시 시도 해주세요.');
            });
        },
        handleClickReject: () => {}, //reject
      });
    }

    // if(!container.state){

    // }else{
    //     //현재 컨테이너내에서 실행하는 프로젝트 같음

    //     if(container.project_id === projectId){
    //         const token = localStorage.getItem('token')
    //         const userId = localStorage.getItem('uid');
    //         const url = `${process.env.REACT_APP_IP}/users/editor/${userId}/${projectId}`;
    //         api.get(url, {
    //             headers: {
    //                 "AccessToken": `Bearer ${token}`
    //             }
    //         })
    //         .then(response => {
    //             const { data } = response;
    //             window.open(
    //                 `${process.IP}:${data.vscode_port}/?folder=/home/coder/projects/`,
    //                 '_blank'
    //             );
    //         })
    //             .catch(error => {
    //
    //         })
    //     }else{
    //         //현재 컨테이너 내에 실행하는 프로젝트과 다른 경우
    //         Alert({
    //             title: `기존 컨테이너를 종료하고 새로 시작 하시겠습니까?`,
    //             handleClickAccept: () => { //accept
    //                 const token = localStorage.getItem('token')
    //                 const userId = localStorage.getItem('uid');
    //                 const url = `${process.env.REACT_APP_IP}/users/editor/${userId}/${projectId}`;
    //                 api.get(url, {
    //                     headers: {
    //                         "AccessToken": `Bearer ${token}`
    //                     }
    //                 })
    //                 .then(response => {
    //                     const { data } = response;
    //                     props.handleUpdateContainer(data.projectId);
    //                     window.open(
    //                         `${process.IP}:${data.vscodePort}/?folder=/home/coder/projects/`,
    //                         '_blank'
    //                     );
    //                 })
    //                     .catch(error => {
    //
    //                 })

    //             },
    //             handleClickReject: () => {} //reject
    //         })
    //     }
    // }
  };
  const handleDeleteProject = (projectId) => {
    const { container } = props;
    let titleDiag = '';
    if (container.project_id === projectId) {
      titleDiag = '해당하는 프로젝트를 컨테이너 올리고 있는데 그래도 삭제하시 겠습니까?';
    } else {
      titleDiag = '프로젝트를 삭제하시겠습니까?';
    }
    Alert({
      title: titleDiag,
      // content: `핵생이 남아있는 경우, 모도 퇴장됩니다.`,
      handleClickAccept: () => {
        //accept
        api
          .delete(`/users/projects/${projectId}`)
          .then((response) => {
            if (response.data === 'success') props.handleDeleteProject(projectId);
          })
          .catch((error) => {
            errorToast('프로젝트가 삭제 되지 않았습나다.');
          });
      },
      handleClickReject: () => {}, //reject
    });
  };
  return (
    <Card className={classes.root} style={{}}>
      <div style={{ textAlign: 'right' }}>
        <Tooltip title='프로젝트 삭제'>
          <IconButton onClick={() => handleDeleteProject(project.projectId)} style={{ margin: '10px 10px 0 0' }}>
            <i className='far fa-trash-alt' style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
          </IconButton>
        </Tooltip>
      </div>
      <CardContent style={{ padding: '0px' }}>
        {
          <>
            <Typography variant='h6'>{project.projectName}</Typography>
            <img src={getURLImageProject(project.projectType)} className={classes.projectImage} alt='projectImage' />
          </>
        }
      </CardContent>
      <CardActions style={{ position: 'absolute', bottom: '15px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant='button' display='block' style={{ color: '#2F96EB' }}>
              {moment(project.projectCreated).format('YYYY/MM/DD /HH:mm:ss')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Tooltip title='콘테이너 만들기'>
              <IconButton onClick={() => handleClickViewProject(project.projectPath, project.projectId)}>
                <i className='fas fa-cube' style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
              </IconButton>
            </Tooltip>
            <Tooltip title='코드 에디터'>
              <IconButton onClick={() => handleClickViewProject(project.projectPath, project.projectId)}>
                <i className='fas fa-file-code' style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
              </IconButton>
            </Tooltip>
            <Tooltip title='프로젝트 진단'>
              <IconButton aria-label='enter'>
                <Link to={`diagnosisreport?pid=${project.projectId}`}>
                  <i className='fas fa-tasks' style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
                </Link>
              </IconButton>
            </Tooltip>
            <Tooltip title='진단기록 확인'>
              <IconButton aria-label='enter'>
                <Link to={`diagnosishistory?pid=${project.projectId}`}>
                  <i className='far fa-chart-bar' style={{ fontSize: '16px', color: '#2F96EB', padding: '5px' }}></i>
                </Link>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
