import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation, withRouter } from 'react-router';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import * as XLSX from 'xlsx';
import styled from 'styled-components';

import { Header } from '../../../widgets';
import { useRedirectWithoutOperator } from 'hooks/redirect.hook';
import { useStyles } from 'style';
import BootstrapInput from 'style/customerPageStyle/BootstrapInput';
import { fetchGroupAllowedCourse, fetchGroupExamStatus, fetchGroupUserStatus } from 'api/operator';
import './OperatorGroup.css';
import styles from '../../Mypage/Mypage.module.scss';
import OperatorGroupInfo from '../OperatorGroupInfo';
import { NoResult } from '../../../elements';
import { EXAM_ORDER } from '../../../../constants/common';

const OperatorGroup = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [userData, setUserData] = useState([]);
  const [searchedData, setSearched] = useState([]);
  const [groupExamInfo, setGroupExamInfo] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [search, setSearch] = useState({
    selected: 'userName',
    title: '이름',
    content: '',
  });
  const [groupId, setGroupId] = useState();
  const [examYn, setExamYn] = useState('N');
  const [examActive, setExamActive] = useState([]);
  const [languageName, setLanguageName] = useState('');

  /* [examId, languageId, languageName] */
  const [examActiveStatus, setExamActiveStatus] = useState(false);
  const [selectedExamId, setSelectedExamId] = useState(0);

  const [allowedCourses, setAllowedCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(location.state.defaultCourseId);

  const selectedExamInfo = useMemo(() => {
    return examActive.find((exam) => exam.examId === selectedExamId) || {};
  }, [examActive, selectedExamId]);

  const handleSelectExamIdChange = (e) => {
    setSelectedExamId(Number(e.target.value));
  };

  useRedirectWithoutOperator();

  const handleChangeCourseId = (e) => {
    setSelectedCourseId(Number(e.target.value));
  };

  useEffect(() => {
    if (selectedExamInfo && selectedExamInfo.examActive === 1) {
      setExamActiveStatus(true);
    } else {
      setExamActiveStatus(false);
    }
  }, [selectedExamId, selectedCourseId]);

  const clickToMyPage = () => {
    history.push('/operator');
  };

  useEffect(() => {
    /* 해당 그룹에 허용된 과목 조회 */
    (async () => {
      const { data } = await fetchGroupAllowedCourse(location.state.groupId);
      setAllowedCourses(data.data);
    })();
  }, []);

  useEffect(() => {
    if (!selectedCourseId) return;

    (async () => {
      try {
        const { data } = await fetchGroupUserStatus(location.state.groupId, selectedCourseId);
        setUserData(data.data);
        setSearched(data.data);
        setGroupId(data.data[0].groupId);
        setGroupExamInfo(data.data[0]);
        setExamYn(data.data[0].groupExamYn);
      } catch (error) {}
    })();
  }, [selectedCourseId]);

  const fetchExamActiveStatus = async () => {
    if (examYn && examYn === 'Y') {
      try {
        const response = await fetchGroupExamStatus(groupId, selectedCourseId);

        if (response.data.length <= 0) {
          setExamActive([]);
          return;
        }

        if (examActiveStatus) {
          setExamActive(response.data);
          setSelectedExamId((prev) => prev);
          return;
        }

        setSelectedExamId(response.data[0].examId);
        setExamActive(response.data);
        setLanguageName(response.data[0].languageName);
      } catch (error) {
        if (error.response !== undefined) {
          history.push('/');
        }
      }
    }
  };

  useEffect(() => {
    fetchExamActiveStatus();
  }, [examYn, selectedCourseId, examActiveStatus]);

  const selectChange = (e) => {
    if (e.target.value === 'userName') {
      setSearch({ ...search, selected: e.target.value, title: '이름' });
      setSearched(
        userData.filter((l) => {
          return l.userName.indexOf(search.content) !== -1;
        })
      );
    }
  };

  const handleChangeSearch = (e) => {
    if (search.selected === 'userName') {
      setSearched(
        userData.filter((l) => {
          return l.userName.indexOf(e.target.value) !== -1;
        })
      );
    }
    setSearch({ ...search, content: e.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const goToGroupReportDetail = (userId, event) => {
    event.preventDefault();
    history.push({
      pathname: '/operator/learning-result',
      state: {
        userId: userId,
        groupId: groupId,
        languageId: selectedExamInfo.languageId,
      },
    });
  };

  const goToExamUserListPage = () => {
    history.push({
      pathname: `/operator/exam-users/${selectedExamInfo.languageId}`,
      state: {
        groupId,
        languageId: selectedExamInfo.languageId,
        groupExamInfo,
        languageName,
      },
    });
  };

  const tableToExcel = () => {
    // work_book 생성
    const wb = XLSX.utils.book_new();
    // 강의 학습, 보안약점 진단훈련 work_sheet 생성
    const ws1 = XLSX.utils.table_to_sheet(document.getElementById('groupUserListForPrint'), { raw: true });

    // work_book에 work_sheet 추가
    XLSX.utils.book_append_sheet(wb, ws1, groupExamInfo.groupCourse);

    // work_sheet 컬럼(순서대로) 너비 설정
    ws1['!cols'].push(
      { width: 4 },
      { width: 10 },
      { width: 23 },
      { width: 16 },
      { width: 10 },
      { width: 15 },
      { width: 10 },
      { width: 15 },
      { width: 10 },
      { width: 15 }
    );

    XLSX.writeFile(wb, `SECOLAB_${groupExamInfo.groupCourse}_report.xlsx`);
  };

  const ref = useRef(null);

  const handleClickMonitoring = () =>
    history.push({
      pathname: '/operator/monitoring',
      search: `?groupId=${groupExamInfo.groupId}&languageId=${selectedExamInfo.languageId}&examOrder=${selectedExamInfo.examCount}`,
    });

  return (
    <Fragment>
      <Header>{groupExamInfo.groupCourse} 교육생 목록</Header>
      <div className={styles.mypage} ref={ref}>
        <section className={`${styles.wrapper} ${styles.with_sidebar} ${styles.left}`}>
          <article className='mypage'>
            <div className='mypageTable'>
              {/* 그룹 정보 테이블 */}
              <OperatorGroupInfo groupInfo={groupExamInfo} />

              <MenualWrapper>
                <div>
                  <button onClick={clickToMyPage} className='toMyPageBtn'>
                    <span className='secotext'>
                      <i className='fas fa-list'></i>&nbsp;&nbsp;다른 그룹 보기
                    </span>
                  </button>
                </div>

                <SelectBoxWrapper>
                  <Select
                    variant='outlined'
                    id='select'
                    value={selectedCourseId}
                    onChange={handleChangeCourseId}
                    input={<BootstrapInput />}
                    sx={{ marginLeft: '5px' }}
                  >
                    {allowedCourses?.map((course, index) => {
                      return (
                        <MenuItem key={index} className='secotext' value={course.courseId}>
                          {`${course.courseName}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </SelectBoxWrapper>

                <SelectBoxWrapper style={{ marginLeft: 'auto' }}>
                  <StyledForm>
                    <Select
                      variant='outlined'
                      id='select'
                      value={search.selected}
                      onChange={selectChange}
                      input={<BootstrapInput />}
                      sx={{ width: '80px' }}
                    >
                      <MenuItem className='secotext' value='userName'>
                        이름
                      </MenuItem>
                    </Select>

                    <BootstrapInput
                      placeholder={`${search.title}으로 검색하기`}
                      name='content'
                      value={search.content}
                      onChange={handleChangeSearch}
                      style={{ width: '200px' }}
                    />
                  </StyledForm>
                </SelectBoxWrapper>
              </MenualWrapper>

              <div>
                {searchedData.length <= 0 && <NoResult />}

                {searchedData.length > 0 && (
                  <>
                    <TableContainer component={Paper}>
                      <Table className={classes.table}>
                        <TableHead rowcount={userData.length}>
                          <StyledTableRow className='header'>
                            <StyledTd>No.</StyledTd>
                            <StyledTd>이름</StyledTd>
                            <StyledTd>이메일</StyledTd>
                            <StyledTd>영상학습</StyledTd>
                            <StyledTd>진행률</StyledTd>
                            <StyledTd>진단훈련</StyledTd>
                            <StyledTd>개발훈련</StyledTd>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {searchedData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            ?.map((row, index) => {
                              return (
                                <StyledTableRow key={index} onClick={(e) => goToGroupReportDetail(row.userId, e)}>
                                  <StyledTd align='center'>{index + 1}</StyledTd>
                                  <StyledTd align='center'>{row.userName}</StyledTd>
                                  <StyledTd align='center'>{row.email}</StyledTd>
                                  <StyledTd align='center'>{row.courseName}</StyledTd>
                                  <StyledTd align='center'>
                                    {row.trainProgress}/{row.courseCount} |{' '}
                                    {(row.trainProgress / row.courseCount) * 100 > row.groupCompletePercentage
                                      ? 'O'
                                      : 'X'}{' '}
                                  </StyledTd>
                                  <StyledTd>
                                    {row.solvedCodeTotalCnt}/{row.codeTotalCnt} |{' '}
                                    {(row.solvedCodeTotalCnt / row.codeTotalCnt) * 100 > row.groupCompletePercentage
                                      ? 'O'
                                      : 'X'}
                                  </StyledTd>
                                  <StyledTd style={{ paddingLeft: '50px' }}>-</StyledTd>
                                </StyledTableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[15, 25]}
                      component='div'
                      count={searchedData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                )}
              </div>
            </div>
          </article>
        </section>
      </div>

      <TableContainer component={Paper} id='groupUserListForPrint' style={{ display: 'none' }}>
        <Table className={classes.table} size='small' aria-label='customized table'>
          <TableHead rowcount={userData.length}>
            <StyledTableRow>
              <StyledTd>No.</StyledTd>
              <StyledTd>이름</StyledTd>
              <StyledTd>이메일</StyledTd>
              <StyledTd>영상학습</StyledTd>
              <StyledTd>진행률</StyledTd>
              <StyledTd>영상 수료 여부</StyledTd>
              <StyledTd>진단훈련</StyledTd>
              <StyledTd>훈련 수료 여부</StyledTd>
              <StyledTd>개발훈련</StyledTd>
              <StyledTd>최종 수료 여부</StyledTd>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {searchedData?.map((row, index) => {
              let courseResult;
              let codeResult;
              {
                (row.trainProgress / row.courseCount) * 100 > row.groupCompletePercentage
                  ? (courseResult = 'O')
                  : (courseResult = 'X');
              }
              {
                (row.solvedCodeTotalCnt / row.codeTotalCnt) * 100 > row.groupCompletePercentage
                  ? (codeResult = 'O')
                  : (codeResult = 'X');
              }
              return (
                <StyledTableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell align='center'>
                    <Link to='#' onClick={(e) => goToGroupReportDetail(row.userId, e)} className='secotext'>
                      {row.userName}
                    </Link>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secotext'>{row.email}</span>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secotext'>{row.courseName}</span>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secotext'>
                      {row.trainProgress}/{row.courseCount}{' '}
                    </span>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secotext'>{courseResult} </span>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secotext'>
                      {row.solvedCodeTotalCnt}/{row.codeTotalCnt}
                    </span>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secotext'>{codeResult} </span>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secotext'> - </span>
                  </TableCell>
                  <TableCell align='center'>
                    <span className='secotext'>{courseResult === 'O' && codeResult === 'O' ? 'O' : 'X'} </span>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className='d-flex justify-content-center align-items-center flex-column'>
        {examActive.length > 0 && examYn === 'Y' && (
          <>
            <StyledFormControl>
              <Select
                variant='outlined'
                style={{ flex: 2 }}
                id='select'
                value={selectedExamId}
                onChange={handleSelectExamIdChange}
                input={<BootstrapInput />}
              >
                {examActive.map((exam, index) => {
                  return (
                    <MenuItem key={index} className='secotext' value={exam.examId}>
                      {`${exam.languageName.toUpperCase()} ${EXAM_ORDER[exam.examCount]} 평가`}
                    </MenuItem>
                  );
                })}
              </Select>

              <button onClick={handleClickMonitoring} className='activeBtn'>
                <span className='secotext'>시험 모니터링</span>
              </button>

              {selectedExamInfo.examActive !== 2 ? (
                <button className='activeBtn' disabled>
                  <span className='secotext'>시험 종료 전</span>
                </button>
              ) : (
                <>
                  <button onClick={goToExamUserListPage} className='activeBtn'>
                    <span className='secotext'>시험 결과 분석</span>
                  </button>
                </>
              )}
            </StyledFormControl>
          </>
        )}

        <button onClick={() => tableToExcel()} className='excelBtn'>
          <i className='far fa-file-excel'></i> Excel 다운로드
        </button>
      </div>
    </Fragment>
  );
};

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: #f1f1f1;
  }

  &.header {
    background-color: #2e94d2;
    color: #fff;
    font-weight: bold;
  }
`;

const StyledTd = styled.td`
  padding: 20px;
  font-size: 1.6rem;
`;

const MenualWrapper = styled.div`
  width: 100%;
  padding: 0;
  margin-bottom: 1vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-size: 2em;

  input {
    padding: 10px 5px;
  }
`;

const SelectBoxWrapper = styled.div`
  .MuiSelect-icon {
    top: unset !important;
  }
`;

const StyledForm = styled.form`
  display: flex !important;
  flex-direction: row;
  column-gap: 10px;
`;

const StyledFormControl = styled(FormControl)`
  width: 40%;
  display: flex;
  flex-direction: row !important;
  column-gap: 10px;
  padding-bottom: 30px !important;
`;

export default withRouter(OperatorGroup);
