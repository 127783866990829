import { TableCell } from '@mui/material';
import { withStyles } from '@mui/styles';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#a8a9a9',
    color: '#fff',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

export default StyledTableCell;
