import React, { useState } from 'react';
import { TextField, Container, Grid, InputLabel, FormControl, Select, Checkbox, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useCustomState } from 'state/state';
import { errorToast, successToast } from 'utilities/toast';
import { checkSignupUserEmail, checkSignupUserId, signup, userConfirmCode } from 'api/auth';
import { Button } from '../../elements';
import styles from './SignUpForm.module.scss';

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: '25px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2px',
  },
  selectEmpty: {
    marginTop: '10px',
  },
  avatar: {
    margin: '3px',
    backgroundColor: '#0069d9',
  },
  button: {
    color: 'white',
    fontSize: '1.4rem',
    margin: '15px 0 10px',
    backgroundColor: '#343a40',
    cursor: 'pointer',
    border: 'thin solid #343a40 !important',
  },

  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  TextField: {
    label: {
      fontSize: '10px',
    },
  },
}));

const SignupForm = () => {
  const classes = useStyles();
  const actions = useCustomState()[1];

  const [values, setValues] = useState({
    userId: '',
    confirmUserId: 'no',
    email: '',
    confirmCode: '',
    password: '',
    confirmPassword: '',
    phone: '',
    name: '',
    type: '',
    useConfirm: 'no',
    ruleCheck: 1,
  });

  const [submitEmail] = useState(false);
  const [submitUserId, setSubmitUserId] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState('no');
  const [confirmStatusEmail, setConfirmStatusEmail] = useState('no');
  const [confirmStatusConfirmCode, setConfirmStatusConfirmCode] = useState('no');
  const [useConfirmStatus, setUseConfirmStatus] = useState('no');

  const [state, setState] = useState({
    userT: '',
    name: '',
  });

  const [checked, setChecked] = useState(false);

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
    event.target.checked ? setUseConfirmStatus('true') : setUseConfirmStatus('no');
  };
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const [error, setError] = useState({
    userIdError: '',
    emailError: '',
    emailCodeError: '',
    pwdError: '',
    confirmPwd: '',
    nameError: '',
    phoneError: '',
    userTypesError: '',
    useConfirmError: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const isUserId = (userId) => {
    const userIdRegex = /^[a-z0-9_!@$%^&*-+=?"]{1,20}$/;

    return userIdRegex.test(userId);
  };

  const isEmail = (email) => {
    const emailRegex =
      /^(([^<>()\].,;:\s@"]+(\.[^<>()\].,;:\s@"]+)*)|(".+"))@(([^<>()¥[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    return emailRegex.test(email);
  };
  const isPwd = (pass) => {
    const pwdRegex = /^.*(?=.{6,20})(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).*$/;

    return pwdRegex.test(pass);
  };

  const isPhone = (phone) => {
    const phoneRegex = /^[0-9\b -]{0,13}$/;
    return phoneRegex.test(phone);
  };

  const confirmPassword = (pass, confirmPass) => {
    return pass === confirmPass;
  };

  const onTextValidation = () => {
    let userIdError = '';
    let emailError = '';
    let emailCodeError = '';
    let pwdError = '';
    let confirmPwd = '';
    let nameError = '';
    let phoneError = '';
    let userTypesError = '';
    let useConfirmError = '';

    if (!isUserId(values.userId)) userIdError = '아이디 형식을 확인 해 주세요.( 한글 불가 )';
    if (!isEmail(values.email)) emailError = 'email 형식이 아닙니다.';
    if (!isPwd(values.password)) pwdError = '비밀번호 조건을 만족 할 수 없습니다.';
    if (!confirmPassword(values.password, values.confirmPassword)) confirmPwd = '비밀번호가 일치하지 않습니다.';
    if (values.userId === values.password) pwdError = '아이디를 비밀번호로 사용 할 수 없습니다.';
    if (!isPhone(values.phone)) phoneError = '휴대폰 형식이 아닙니다.';
    if (!confirmStatus || confirmStatus === 'no') userIdError = '아이디 중복확인 하세요';
    if (!confirmStatusEmail || confirmStatusEmail === 'no') emailError = '이메일 중복확인 하세요';
    if (!confirmStatusConfirmCode || confirmStatusConfirmCode === 'no') emailCodeError = '인증 번호를 확인 해주세요';
    if (useConfirmStatus === 'no') {
      errorToast('개인정보 수집 및 이용 동의를 해주세요.');
      useConfirmError = '개인정보 수집 및 이용 동의를 해주세요.';
    }
    if (values.name.length === 0) nameError = '이름을 입력해주세요.';
    if (values.name.length === 0) userTypesError = '사용사 형식을 선택해 주세요.';

    setError({
      userIdError,
      emailError,
      emailCodeError,
      pwdError,
      confirmPwd,
      nameError,
      phoneError,
      useConfirmError,
    });

    if (
      userIdError ||
      emailError ||
      emailCodeError ||
      pwdError ||
      confirmPwd ||
      nameError ||
      phoneError ||
      userTypesError ||
      useConfirmError
    )
      return false;
    return true;
  };

  const handleChangeForm = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const valid = onTextValidation();

    if (isLoading) return;

    if (!valid) console.error('invalid');
    else {
      //1: 학습자, 2: 출제자
      let userType = state.userT === 'submitter' ? 2 : 1;

      try {
        setIsLoading(true);
        await signup({
          userId: values.userId,
          name: values.name,
          email: values.email,
          password: values.password,
          phone: values.phone,
          type: userType,
          ruleCheck: values.ruleCheck,
        });
        successToast('회원가입이 완료되었습니다.');
        setIsLoading(false);
        history.push('/');
      } catch (error) {
        errorToast('이미 존재하는 회원입니다.');
        setValues({
          userId: '',
          email: '',
          password: '',
          name: '',
          confirmPassword: '',
          phone: '',
          ruleCheck: 1,
        });
        setIsLoading(false);
      }
    }
  };

  const handleConfirmUserId = async () => {
    if (!submitUserId) {
      if (!values.userId || !isUserId(values.userId)) {
        errorToast(values.userId + '는 사용할 수 없는 형식입니다.');
      } else {
        try {
          setSubmitUserId(false);
          const response = await checkSignupUserId({ userId: values.userId });
          const { data } = response;
          if (data === 'success') {
            setConfirmStatus(true);
            successToast('사용 가능한 아이디입니다.');
          } else {
            setConfirmStatus(false);
            errorToast('이미 사용하고 있는 아이디입니다.');
          }
        } catch (_) {
          errorToast('서버와의 연결이 원할 하지 않습니다. 잠시 후에 다시 시도 해주세요.');
        }
      }
    }
  };

  const handleConfirmEmail = async () => {
    if (!values.email) {
      errorToast('이메일을 입력하세요.');
    } else if (!isEmail(values.email)) {
      errorToast(values.email + '는 사용할 수 없는 형식입니다.');
    } else {
      try {
        setConfirmStatusEmail(false);
        const response = await checkSignupUserEmail({ email: values.email });
        const { data } = response;
        if (data === 'success') {
          setConfirmStatusEmail(true);
          successToast(
            '입력하신 이메일 주소로 인증번호를 발송했습니다. 받은 편지함을 확인하셔서 전달된 인증번호를 입력해 주세요.'
          );
        } else if (data === 'sendMailErrror') {
          setConfirmStatusEmail(false);
          errorToast('인증번호를 발송할 수 없습니다. 이메일 주소를 확인하신 후 다시 시도해 주세요.');
        } else {
          setConfirmStatusEmail(false);
          errorToast('이미 사용하고 있는 이메일 주소입니다.');
        }
      } catch (error) {
        errorToast('서버와의 연결이 원할하지 않습니다. 잠시 후 다시 시도해 주세요.');
      }
    }
  };

  //  이메일 인증코드 확인
  const handleConfirmCode = async () => {
    if (confirmStatusEmail !== true) {
      errorToast('이메일 인증을 먼저 시도해 주세요.');
    } else if (!values.confirmCode) {
      errorToast('인증번호를 입력하세요.');
    } else {
      try {
        setConfirmStatusConfirmCode(false);
        const response = await userConfirmCode({
          email: values.email,
          confirmCode: values.confirmCode,
        });
        const { data } = response;
        if (data === 'success') {
          setConfirmStatusConfirmCode(true);
          successToast('인증되었습니다. 계속해서 회원가입을 진행해 주세요.');
        } else {
          setConfirmStatusConfirmCode(false);
          errorToast(
            '인증번호 확인에 실패했습니다. 인증번호를 확인하신 후 다시 시도해 주세요. 인증제한시간(5분)이 초과한 경우 이메일 인증부터 다시 시도해 주세요.'
          );
        }
      } catch (error) {
        errorToast('서버와의 연결이 원할하지 않습니다. 잠시 후 다시 시도해 주세요.');
      }
    }
  };

  return (
    <Container maxWidth='sm' style={{ backgroundColor: 'white', minHeight: '900px' }}>
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                helperText='한글은 사용할 수 없습니다.'
                variant='outlined'
                margin='normal'
                required
                autoComplete='userId'
                autoFocus
                label='아이디를 입력하세요.'
                name='userId'
                fullWidth
                value={values.userId}
                onChange={handleChangeForm}
              />
              <div
                style={{
                  color: 'red',
                  fontSize: '12px',
                  margin: '-5px 0 10px 15px',
                  fontFamily: 'Noto Sans KR',
                }}
              >
                {error.userIdError}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.btn_signup}>
                <Button
                  roundSmall
                  className='relativeBtn'
                  type='outline-color-tw'
                  hoverType='solid-color-tw'
                  click={() => handleConfirmUserId()}
                >
                  <span className='secomin'>아이디 중복확인</span>
                </Button>
              </div>
            </Grid>

            <Grid item xs={8}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                autoComplete='email'
                fullWidth
                label='이메일을 입력하세요.'
                name='email'
                value={values.email}
                onChange={handleChangeForm}
              />
              <div
                style={{
                  color: 'red',
                  fontSize: '12px',
                  margin: '-5px 0 10px 15px',
                  fontFamily: 'Noto Sans KR',
                }}
              >
                {error.emailError}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.btn_signup}>
                <Button
                  type='submit'
                  size='large'
                  variant='contained'
                  color='primary'
                  roundSmall
                  //endIcon={<EmojiPeopleIcon/>}
                  click={() => handleConfirmEmail()}
                  style={{
                    margin: '16px 0 0 10px',
                    padding: '16px 36px',
                    borderRadius: '5px',
                    fontSize: '13px',
                    fontWeight: '500',
                    fontFamily: 'Noto Sans KR',
                  }}
                >
                  <span className='secomin'>이메일 인증</span>
                </Button>
              </div>
            </Grid>
            <Grid item xs={8}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                autoComplete='code'
                fullWidth
                label='이메일로 전달된 인증번호를 입력하세요.'
                name='confirmCode'
                value={values.confirmCode}
                onChange={handleChangeForm}
              />
              <div
                style={{
                  color: 'red',
                  fontSize: '12px',
                  margin: '-5px 0 10px 15px',
                  fontFamily: 'Noto Sans KR',
                }}
              >
                {error.emailCodeError}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.btn_signup}>
                <Button
                  type='submit'
                  size='large'
                  variant='contained'
                  color='primary'
                  roundSmall
                  //endIcon={<EmojiPeopleIcon/>}
                  click={() => handleConfirmCode()}
                  style={{
                    margin: '16px 0 0 10px',
                    padding: '16px 36px',
                    borderRadius: '5px',
                    fontSize: '13px',
                    fontWeight: '500',
                    fontFamily: 'Noto Sans KR',
                  }}
                >
                  <span className='secomin'>인증번호 확인</span>
                </Button>
              </div>
            </Grid>
            {/*
              <Grid item xs={4} >
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  //endIcon={<EmojiPeopleIcon/>}
                  onClick={handleConfirmEmail}
                  style={{ margin: "15px 0 0 0" , padding: "15px 0",borderRadius:"5px"}}
                >
                이메일 중복확인
                </Button>
              </Grid>
            */}

            {/* <div style={{display: 'flex'}}>
              <TextField 
                  fullWidth
                  label="confirm"
                  name="confirmEmail"
                  onChange={handleChangeForm}
                />
                {
                  confirmStatus !== 'no' ?
                  confirmStatus ? 
                  <Alert severity="success"></Alert> :
                  <Alert severity="error"></Alert>
                  : ""
                }
                <div style={{width: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  {
                    submitEmail &&
                    <CountDownTime handleDownAllTime = {handleDownAllTime} />
                  }
              </div>
                <Button variant="contained" color="primary" onClick={() => handleConfirmEmail()}>{submitEmail ? "확인" : "인증"}</Button>
              </div>
              <div style={{ color: "red", fontSize: "12px" }}>
                {error.emailError}
              </div> 
            */}
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                autoComplete='current-password'
                fullWidth
                helperText='영문과 숫자, 특수문자(@$!%*#?&)를 조합하여 8-15자 사이로 입력해 주세요.'
                label='비밀번호를 입력하세요.'
                name='password'
                type='password'
                value={values.password}
                onChange={handleChangeForm}
              />
              <div
                style={{
                  color: 'red',
                  fontSize: '12px',
                  margin: '-5px 0 10px 15px',
                  fontFamily: 'Noto Sans KR',
                }}
              >
                {error.pwdError}
              </div>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                autoComplete='current-password'
                fullWidth
                label='비밀번호 다시한번 입력하세요.'
                name='confirmPassword'
                type='password'
                value={values.confirmPassword}
                onChange={handleChangeForm}
              />
              <div
                style={{
                  color: 'red',
                  fontSize: '12px',
                  margin: '-5px 0 10px 15px',
                  fontFamily: 'Noto Sans KR',
                }}
              >
                {error.confirmPwd}
              </div>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                autoComplete='name'
                fullWidth
                label='이름을 입력하세요.'
                name='name'
                value={values.name}
                onChange={handleChangeForm}
              />
              <div
                style={{
                  color: 'red',
                  fontSize: '12px',
                  margin: '-5px 0 10px 15px',
                  fontFamily: 'Noto Sans KR',
                }}
              >
                {error.nameError}
              </div>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                autoComplete='phone'
                fullWidth
                label='전화번호'
                name='phone'
                value={values.phone}
                onChange={handleChangeForm}
              />
              <div
                style={{
                  color: 'red',
                  fontSize: '12px',
                  fontFamily: 'Noto Sans KR',
                }}
              >
                {error.phoneError}
              </div>
            </Grid>

            <Grid item xs={12}>
              <FormControl variant='outlined' margin='normal' fullWidth required className={classes.formControl}>
                <InputLabel htmlFor='outlined-age-native-required'>사용자 타입을 선택해 주세요.</InputLabel>
                <Select
                  variant='outlined'
                  value={state.userT}
                  onChange={handleChange}
                  label='사용자 타입을 선택해 주세요.'
                  inputProps={{
                    name: 'userT',
                    id: 'outlined-age-native-required',
                  }}
                >
                  <MenuItem className='secotext' aria-label='None' value=''></MenuItem>
                  <MenuItem className='secotext' value='student'>
                    학습자
                  </MenuItem>
                  <MenuItem className='secotext' value='submitter'>
                    출제자
                  </MenuItem>
                </Select>
              </FormControl>
              <div
                style={{
                  color: 'red',
                  fontSize: '12px',
                  margin: '-5px 0 10px 15px',
                  fontFamily: 'Noto Sans KR',
                }}
              >
                {error.userTypesError}
              </div>
            </Grid>

            <Grid item xs={9}>
              <Checkbox
                checked={checked}
                color='primary'
                //size='small'
                value={values.ruleCheck}
                onChange={handleCheckChange}
                //inputProps={{ 'aria-label': 'checkbox with small size' }}
                style={{ marginLeft: '-12px', marginTop: '-5px' }}
              />
              <span className='secotext secoblue'>개인정보 수집 및 이용 동의</span>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.btn_signup_small}>
                <Button roundSmall small type='solid_gray_tb' click={() => actions.toggleRequest()}>
                  <span className='secomin'>
                    전문보기&nbsp;&nbsp;<i className='far fa-clone'></i>
                  </span>
                </Button>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div
                style={{
                  paddingTop: '10px',
                  borderBottom: '1px solid #e3e3e3',
                  margin: '15px 0',
                  fontFamily: 'Noto Sans KR',
                }}
              ></div>
            </Grid>
          </Grid>

          <button
            type='submit'
            size='large'
            variant='contained'
            color='primary'
            //endIcon={<EmojiPeopleIcon/>}
            className={classes.button}
            style={{
              margin: 'auto',
              marginTop: '20px',
              padding: '10px 30px',
              borderRadius: '50px',
              fontFamily: 'Noto Sans KR',
            }}
          >
            <span className='secosub2'>회원 가입 신청</span>
          </button>
          {/*
            <Grid container justify="flex-end">
              <Grid item>
                <Link variant="body2" component={RouterLink} to="/student">
                  <span>
                    Already have an account? Sign in
                  </span>
                </Link>
              </Grid>
            </Grid> 
          */}
        </form>
        {/*<Typography variant="button" display="block" style={{ paddingTop: "10px", paddingBottom: "10px", fontWeight: 'bold' }}>SNS계정으로 회원가입</Typography>
        <div className={classes.container4}>
          <GoogleLogin
            clientId="84082679678-gv37lf5p2n95mqo50asp7cgtcroiqsln.apps.googleusercontent.com"
            buttonText="Sign In with Google"
            onSuccess={responseGoogle}
            onFailure={responseFail}
            className="google_signup"
	        style={{padding:"10px 0", borderRadius:"50px"}}
          />
        </div>
        <div style={{ height: "20px" }}>&nbsp;</div>
        <div className={classes.container3}>
          <KakaoLogin
            jsKey="bbc9d6aa0684080480b7be1e7343e43d"
            buttonText="Kakao"
            onSuccess={responseKakao}
            onFailure={responseFail}
            getProfile={true}
            className="kakao_signup"
	        style={{padding:"10px 0", borderRadius:"50px"}}
          ><img style={{ position: "relative", top: "5px", right: "10px" }} width="20px" height="20px" src={kakao_png} />Sign In with Kakao
                    </KakaoLogin>
        </div>*/}
      </div>
    </Container>
  );
};

export default SignupForm;
