import React, { Fragment, forwardRef, Component } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import * as monaco from 'monaco-editor';
import MonacoEditor from 'react-monaco-editor';
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';

import Alert from '../Student/components/Alert';
//셀렉터
import ScategorySelect from './ScategorySelect';

import { api } from '../api/config';
import { errorToast } from '../utilities/toast';
import check_modal from '../images/answerCharcter.png';
import error_modal from '../images/errorcharacter.png';
import CodeStepper from './CodeStepper';
import './secureCoding.css';

const drawerWidth = 380; // 왼쪽 학습창 너비
let test = null;
let modifiedModel;

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'aqua',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '1px 1px #eee',
    fontSize: 11,
  },
  arrow: {
    color: 'aqua',
  },
}))(Tooltip);

const useStyles = () => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  appBar: {
    transition: '0.2s',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: '0.2s',
  },

  toolbar: {
    position: 'relative',
    backgroundColor: '#1e1e1e', //'#1b2b34',
    BorderBottom: '1px solid gray',
    boxShadow: '0.5px 0px 2px 0px black',
  },
  toolbar1: {
    position: 'relative',
    backgroundColor: '#2d2d2d', //'#1b2b34',
    boxShadow: '0px 1px 4px 0px black',
    width: '100%',
    zIndex: '999',
  },
  menuButton: {
    marginRight: '7px',
    zIndex: '1300',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    BorderRight: '2px solid red',
    backgroundColor: '#252526',
    boxShadow: '0.5px 0px 2px 0px black',
    overflow: 'hidden',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    // necessary for content to be below app bar
    justifyContent: 'flex-end',
    backgroundColor: '#252526',
    // borderBottom: '1px solid black',
  },
  content: {
    flexGrow: 1,
    transition: '0.2s',
    marginLeft: -drawerWidth,
    // backgroundColor: "red"
  },
  contentShift: {
    transition: '0.2s',
    marginLeft: 0,
  },
  dialogPapaer: {
    width: '70vw',
    height: '75vh',
  },
});

const GuideTitle = ({ children }) => <div className='guideTitle'>{children}</div>;
const HintTitle = ({ children }) => <div className='hintTitle'>{children}</div>;
const Text = ({ children }) => (
  <div className='modalText'>
    {(children || '').split('\n')?.map((line, idx) => {
      return (
        <span key={idx}>
          {line}
          <br />
        </span>
      );
    })}
  </div>
);

const ColorButton = withStyles(() => ({
  root: {
    color: '#252526',
    backgroundColor: '#252526',
    '&:hover': {
      backgroundColor: '#455a64',
    },
  },
}))(Button);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

class SecCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.auth.userId,
      // <Drawer> 서랍 여닫이
      lineChecker: true,
      open: true,
      //hint Modal
      guideOpen: false,
      hintOpen: false,
      // Monaco editor 옵션 참고 : https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.ieditoroptions.html
      options: {
        fontSize: '16.5px',
        readOnly: true, //true : 코드수정 불가 ,  false : 수정 가능
        automaticLayout: true, //true : 부모 div 크기에 맞춰서 자동으로 editor 크기 맞춰줌
        glyphMargin: true, //true: 체크 이미지 넣을 공간이 생김
        scrollBeyondLastLine: true, // true : 스크롤이 가능하게
      },
      //Monaco diff 옵션 참고 : https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.idiffeditoroptions.html#renderindicators
      diffOptions: {
        fontSize: '16.5px',
        glyphMargin: true,
        renderIndicators: false, //eidtor 두개 비교해서 달라진 부분 +/- 가리킴
        scrollBeyondLastLine: true,
        automaticLayout: true,
      },
      lineNumber: [],
      correctLineOpen: false, //정답 모달창
      wrongLineOpen: false, // 오답 모달창
      step: 1, //문제 단계
      width: '', //Monaco editor width
      language: '',
      languageId: '',

      //보안 약점 정보
      secId: '',
      secName: '',
      secPdf: '',
      secVideo: '',
      secCategory: '',
      secIdIndex: '',

      //코드 정보
      scodeId: '',
      scodeNum: '0',
      scodeVulFile: '',
      scodeSecFile: '',
      scodeSecCode: '',
      scodeLineNum: '',
      correctLine: [],

      //문제선택 리스트
      lists: [],

      //1단계 정답 체크
      scodeVulDesc: '',
      scodeSecDesc: '',
      tryNumFirst: 0,
      tryNumSecond: 0,
      scodeSecLine: '',

      //페이지 오픈 시 문제 리스트 중 최근에 푼 문제 index
      problemNumber: '',

      //2단계 정답 체크
      step2Result: 0,
      isError: 0,
      // key: '',

      problemId: '',

      startTime: new Date(),
      scodeGuideImg: '',
      vulAnswerHintImg: '',
      secAnswerHintImg: '',

      decorationsOn: '',
      clickLine: [],
    };
  }

  // 셀렉트 버튼 취약점 선택시 취약점 카테고리 아이디 state를 변경
  bringSecId = (secId) => {
    this.setState({ secId: secId });
    this.setState({ problemNumber: '' });
    document.getElementById('editor').style.display = 'block';
    document.getElementById('diffEditor').style.display = 'none';
    document.getElementById('diffEditor_compare').style.display = 'none';
    document.getElementById('diffEditor_compare1').style.display = 'none';
    this.setState({ step: 1, width: `${document.getElementById('editor').offsetWidth}` + `px` });
    this.setState({ wrongLineOpen: false });

    this.setState({ lineNumber: [] });
    this.setState({ clickLine: [] });
    monaco.editor.getModels()[0].setValue('');

    this.setState({ step: 1 });
    this.setState({ step2Result: 0 });
  };
  // 셀렉트 버튼 보안약점 선택시 보안약점 아이디 state를 변경
  bringScategoryId = (scategoryId) => {
    this.setState({ scategoryId: scategoryId });
    this.setState({ problemNumber: '' });
    document.getElementById('editor').style.display = 'block';
    document.getElementById('diffEditor').style.display = 'none';
    document.getElementById('diffEditor_compare').style.display = 'none';
    document.getElementById('diffEditor_compare1').style.display = 'none';
    this.setState({ step: 1, width: `${document.getElementById('editor').offsetWidth}` + `px` });
    this.setState({ wrongLineOpen: false });

    //하이라이팅 업데이트
    this.setState({ lineNumber: [] });
    this.setState({ clickLine: [] });
    // monaco.editor.getModels()[0].setValue("");

    this.setState({ step: 1 });
    this.setState({ step2Result: 0 });
  };

  hintHandleOpen = () => {
    this.setState({ hintOpen: true });
  };

  hinthandleClose = () => {
    this.setState({ hintOpen: false });
  };

  guideHandleOpen = () => {
    this.setState({ guideOpen: true });
  };

  guideHandleClose = () => {
    this.setState({ guideOpen: false });
  };

  chkLan = (lan) => {
    if (lan === 'java')
      return (
        `
package kr.secolab;
public class HowToUseSecolab {
  public static void main(String[] args) {
    // 
    // 시코랩 진단훈련 이용 안내
    // 
    // 1. 보안약점유형을 선택합니다.
    // 2. 보안약점을 선택합니다.
    // 3. 문제를 선택합니다.
    // 4. 진단대상코드 창에서 보안약점이 있는 라인을 선택하고 [제출] 버튼을 클릭합니다.
    // 5. 수정대상코드 창에서 시큐어코딩 기법을 적용하여 보안약점을 제거하는 코드를 작성하고 [제출] 버튼을 클릭합니다.
    // 6. 마이페이지의 학습현황에서 문제풀이 결과를 확인합니다.
  }    
}
    ` + ' '
      );
    else if (lan === 'c')
      return (
        `
// 
// 시코랩 진단훈련 이용 안내
// 
// 1. 보안약점유형을 선택합니다.
// 2. 보안약점을 선택합니다.
// 3. 문제를 선택합니다.
// 4. 진단대상코드 창에서 보안약점이 있는 라인을 선택하고 [제출] 버튼을 클릭합니다.
// 5. 수정대상코드 창에서 시큐어코딩 기법을 적용하여 보안약점을 제거하는 코드를 작성하고 [제출] 버튼을 클릭합니다.
// 6. 마이페이지의 학습현황에서 문제풀이 결과를 확인합니다.
    ` + ' '
      );
    else if (lan === 'c++')
      return (
        `
// 
// 시코랩 진단훈련 이용 안내
// 
// 1. 보안약점유형을 선택합니다.
// 2. 보안약점을 선택합니다.
// 3. 문제를 선택합니다.
// 4. 진단대상코드 창에서 보안약점이 있는 라인을 선택하고 [제출] 버튼을 클릭합니다.
// 5. 수정대상코드 창에서 시큐어코딩 기법을 적용하여 보안약점을 제거하는 코드를 작성하고 [제출] 버튼을 클릭합니다.
// 6. 마이페이지의 학습현황에서 문제풀이 결과를 확인합니다.
    ` + ' '
      );
    else if (lan === 'csharp')
      return (
        `
// 
// 시코랩 진단훈련 이용 안내
// 
// 1. 보안약점유형을 선택합니다.
// 2. 보안약점을 선택합니다.
// 3. 문제를 선택합니다.
// 4. 진단대상코드 창에서 보안약점이 있는 라인을 선택하고 [제출] 버튼을 클릭합니다.
// 5. 수정대상코드 창에서 시큐어코딩 기법을 적용하여 보안약점을 제거하는 코드를 작성하고 [제출] 버튼을 클릭합니다.
// 6. 마이페이지의 학습현황에서 문제풀이 결과를 확인합니다.
    ` + ' '
      );
    else if (lan === 'python')
      return (
        `
// 
// 시코랩 진단훈련 이용 안내
// 
// 1. 보안약점유형을 선택합니다.
// 2. 보안약점을 선택합니다.
// 3. 문제를 선택합니다.
// 4. 진단대상코드 창에서 보안약점이 있는 라인을 선택하고 [제출] 버튼을 클릭합니다.
// 5. 수정대상코드 창에서 시큐어코딩 기법을 적용하여 보안약점을 제거하는 코드를 작성하고 [제출] 버튼을 클릭합니다.
// 6. 마이페이지의 학습현황에서 문제풀이 결과를 확인합니다.
    ` + ' '
      );
  };

  //학습창 Open
  handleDrawerOpen = () => {
    if (this.state.step === 1) {
      this.setState({
        open: true,
        width: `${document.getElementById('editor').offsetWidth - drawerWidth}` + `px`,
      });
    } else {
      this.setState({
        open: true,
        width: `${document.getElementById('editor').offsetWidth + drawerWidth}` + `px`,
      });

      //에디터 크기 DIV 크기에 맞게 변환, 옵션 automaticLayout: true
      setTimeout(() => test.layout(), 300);
    }
  }; //handleDrawerOpen

  //학습창 close
  handleDrawerClose = () => {
    if (this.state.step === 1) {
      this.setState({
        open: false,
        width: `${document.getElementById('editor').offsetWidth + drawerWidth}` + `px`,
      });
    } else {
      this.setState({
        open: false,
        width: `${document.getElementById('editor').offsetWidth - drawerWidth}` + `px`,
      });

      setTimeout(() => test.layout(), 200);
    }
  };

  editorDidMount = (editor) => {
    document.getElementById('mainFooter').style.display = 'none';

    this.editor = editor;
    this.editor.onDidChangeModelDecorations(() => {
      editor.layout(); // 변한 div에 맞게 자동으로 크기 맞춰줌
    });

    this.editor.onMouseDown((e) => {
      let line = e.target.position.lineNumber;
      let text = editor.getModel().getLineContent(e.target.position.lineNumber); //마우스 클릭한 곳의 내용 가져오기

      let checkItem = this.state.clickLine.find((item) => item.lineNumber === e.target.position.lineNumber);
      if (this.state.clickLine && checkItem !== undefined) {
        this.setState((prevState) => ({
          clickLine: this.state.clickLine.filter((item) => item.lineNumber !== e.target.position.lineNumber),
        }));
        editor.deltaDecorations(
          [checkItem.decoOn[0]],
          [
            {
              range: new monaco.Range(line, 1, line, 1),
              options: {
                isWholeLine: false,
                inlineClassName: '', //글자색
                className: '', //배경 하이라이팅
                glyphMarginClassName: '', // 라인번호 앞 표시
                marginClassName: '', //
              },
            },
          ]
        );
      } else {
        this.setState((prevState) => ({
          clickLine: [
            ...prevState.clickLine,
            {
              lineNumber: e.target.position.lineNumber,
              decoOn: editor.deltaDecorations(
                [],
                [
                  {
                    range: new monaco.Range(line, 1, line, 1),
                    options: {
                      isWholeLine: true,
                      inlineClassName: 'myInlineDecoration', //글자색
                      className: 'lineDecoration', //배경 하이라이팅
                      glyphMarginClassName: 'myGlyphMarginClass', // 라인번호 앞 표시
                      marginClassName: 'marginDecoration', //
                    },
                  },
                ]
              ),
            },
          ],
        }));
      }
    });
  }; //editormount 끝

  // componetWillMount를 사용한 이유?
  componentDidMount = (editor) => {
    // componentWillMount = (editor) => {
    this.editor = editor;

    //에디터 테마 설정, 모나코에디터는 한 화면에 있는 두개의 에디터에 각자 다른 테마를 적용시킬 수 없다.
    import('./Oceanic Next.json').then((data) => {
      monaco.editor.defineTheme('OceanicNext', data);
      monaco.editor.setTheme('OceanicNext');
    });

    //url에서 Language.languageId 추출
    const info = document.location.href.split('/');
    //Language
    this.setState({ language: info[5] });
    //LanguageId
    this.setState({ languageId: info[6] });
    this.setState({ sendLanId: this.state.languageId });

    //SecId 및 scategoryId 는 셀렉트 버튼에서 선택시 해당 value값으로 취득하는 것으로 변경함.
    //기존의 index1.js 에서 값을 페이지가 열림과 동시에 요청하여 처리하던 346-372 부분은 삭제처리함.
  };

  handleSubmitStep1 = () => {
    var submitLine = '';
    for (let i = 0; i < this.state.clickLine.length; i++) {
      submitLine += this.state.clickLine[i].lineNumber;
      if (i !== this.state.clickLine.length - 1) submitLine += ',';
    }

    const elapsedTime = new Date() - this.state.startTime;

    let form = new URLSearchParams();
    form.append('userId', this.state.userId);
    form.append('scodeId', this.state.scodeId);
    form.append('scodeLineNum', submitLine);
    form.append('elapsedTime', elapsedTime);

    api({
      method: 'POST',
      url: `/users/comp/first`,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      data: form,
    })
      .then((response) => {
        this.setState({
          tryNumFirst: response.data.tryNumFirst,
          //tryNumSecond: response.data.tryNumSecond,
          isError: response.data.isError,
        });
        if (response.data.scodeVulDesc != null) {
          if (test === null) {
            this.handleDiff();
          }

          let correctLine = this.state.scodeLineNum
            .split(',')
            .sort(function (left, right) {
              return left - right;
            })
            ?.map(Number);

          for (let i = 0; i < correctLine.length; i++) {
            monaco.editor.getModels()[0].deltaDecorations(monaco.editor.getModels()[0].getAllDecorations(), [
              {
                range: {
                  startLineNumber: correctLine[i],
                  startColumn: 1,
                  endLineNumber: correctLine[i],
                  endColumn: 1,
                },
                options: {
                  isWholeLine: true,
                  inlineClassName: 'myDiffInlineDecoration', //글자색
                  className: 'lineDecoration', //배경 하이라이팅
                  glyphMarginClassName: 'myGlyphMarginClass', // 라인번호 앞 표시
                  marginClassName: 'marginDecoration',
                },
              },
            ]);
          }

          this.setState({
            correctLineOpen: true,
            step: 2,
            startTime: new Date(), // STEP2 시작 시간 설정
          });
          return true;
        } else {
          this.setState({
            wrongLineOpen: true,
          });
        }
      })
      .catch((error) => {});
  };

  handleSubmitStep2 = () => {
    /*
    첫번째로 만들어진 모나코 에디터 : monaco.editor.getModels()[0]
    두번째로 만들어진 모나코 에디터 : monaco.editor.getModels()[1]
    monaco.editor.getModels()[0].getValue() : monaco.editor.getModels()[0]의 전체 코드 값
    diffEditor2.getLineChanges() : diffEditor2의 original 와 modified 를 비교해서 달라진 라인 값
    monaco.editor.getModels()[0].getLineContent(1) : 첫번째 라인의 코드 값

    모나코 플레이 그라운드 : https://microsoft.github.io/monaco-editor/playground.html
    */

    //monaco.editor.getModels()[0].dispose() //에디터 삭제 기능
    var submit = [];
    var diffString = '';

    //코드 고친부분만 서버에 전달   //더 효율적인 방법이 있을지는 생각해봐야함
    var modifiedModel2 = monaco.editor.createModel(this.state.scodeSecCode, this.state.language);
    var modifiedModel3 = monaco.editor.createModel(monaco.editor.getModels()[1].getValue(), this.state.language);

    var diffEditor2 = monaco.editor.createDiffEditor(
      document.getElementById('diffEditor_compare'),
      this.state.diffOptions
    );
    diffEditor2.setModel({
      original: modifiedModel2,
      modified: modifiedModel3,
    });

    setTimeout(() => {
      // modifiedModel2와 modifiedModel3 사이의 달라진 라인넘버를 읽어서 해당 라인값을  submit 에 담아 놓는다.
      for (var i = 0; i < diffEditor2.getLineChanges().length; i++) {
        for (
          var j = 0;
          j <=
          diffEditor2.getLineChanges()[i].modifiedEndLineNumber -
            diffEditor2.getLineChanges()[i].modifiedStartLineNumber;
          j++
        ) {
          diffString +=
            monaco.editor.getModels()[1].getLineContent(diffEditor2.getLineChanges()[i].modifiedStartLineNumber + j) +
            ',';
        }
        submit.push(diffString);
        // diffString = "";
      }

      const elapsedTime = new Date() - this.state.startTime;

      let form_step2 = new URLSearchParams();
      form_step2.append('scodeId', this.state.scodeId);
      form_step2.append('userId', this.state.userId);
      form_step2.append('compileCode', monaco.editor.getModels()[1].getValue());
      form_step2.append('scodeSecCode', diffString);
      form_step2.append('elapsedTime', elapsedTime);

      api({
        method: 'POST',
        url: `/users/comp/second`,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        data: form_step2,
      })
        .then((response) => {
          this.setState({
            //tryNumFirst: response.data.tryNumFirst,
            tryNumSecond: response.data.tryNumSecond,
            isError: response.data.isError,
          });
          if (response.data.scodeSecDesc != null) {
            if (test === null) {
              this.handleDiff();
            }

            let correctLine = this.state.scodeLineNum
              .split(',')
              .sort(function (left, right) {
                return left - right;
              })
              ?.map(Number);

            for (let i = 0; i < correctLine.length; i++) {
              monaco.editor.getModels()[0].deltaDecorations(monaco.editor.getModels()[0].getAllDecorations(), [
                {
                  range: {
                    startLineNumber: correctLine[i],
                    startColumn: 1,
                    endLineNumber: correctLine[i],
                    endColumn: 1,
                  },
                  options: {
                    isWholeLine: true,
                    inlineClassName: 'myDiffInlineDecoration', //글자색
                    className: 'lineDecoration', //배경 하이라이팅
                    glyphMarginClassName: 'myGlyphMarginClass', // 라인번호 앞 표시
                    marginClassName: 'marginDecoration',
                  },
                },
              ]);
            }

            this.setState({ correctLineOpen: true });
            this.setState({ step2Result: 1 });
            this.setState({ step: 3 });

            //문제 선택 리스트
            const info = document.location.href.split('?');
            const sendLanId = info[2].split('=')[1];

            let form = new URLSearchParams();
            form.append('userId', this.state.userId);
            form.append('secId', parseInt(this.state.secId));
            form.append('languageId', sendLanId);

            api({
              method: 'POST',
              url: `/users/sec/list`,
              headers: {
                'Access-Control-Allow-Origin': '*',
              },
              data: form,
            })
              .then((response) => {
                this.setState({ lists: response.data });
              })
              .catch((error) => {});

            return true;
          } else {
            this.setState({
              wrongLineOpen: true,
              step2Result: 1,
            });
          }
        })
        .catch((error) => {});
    }, 100);
  };

  handleModalNext = () => {
    // step1 에디터 숨기고 diffEditor 가져옴
    document.getElementById('editor').style.display = 'none';
    document.getElementById('diffEditor').style.display = 'block';
    document.getElementById('diffEditor').style.height = 'calc(100vh - 0px)';
    this.handleResetStep1();
    this.handleDiff();

    this.setState({
      correctLineOpen: false, //모달창 닫기
      step: 2,
    });

    //하이라이팅 업데이트
    monaco.editor.getModels()[0].deltaDecorations(monaco.editor.getModels()[0].getAllDecorations(), []);
  };

  handleModalNext_1 = () => {
    // step1 에디터 숨기고 diffEditor 가져옴
    document.getElementById('editor').style.display = 'none';
    document.getElementById('diffEditor').style.display = 'block';
    document.getElementById('diffEditor').style.height = 'calc(100vh - 0px)';
    if (test === null) {
      this.handleDiff();
    } else {
      test.getModifiedEditor().setValue(this.state.scodeSecCode);
    }

    this.setState({
      correctLineOpen: false, //모달창 닫기
      step: 2,
    });

    //하이라이팅 업데이트
    monaco.editor.getModels()[0].deltaDecorations(monaco.editor.getModels()[0].getAllDecorations(), []);
  };

  handleModalRetry = (editor) => {
    monaco.editor.getModels()[0].setValue('');
    monaco.editor.getEditors()[0].setScrollTop(0); // 스크롤 맨 위로 이동
    this.setState({ wrongLineOpen: false });
    this.setState({ clickLine: [] });
    this.setState({ lineNumber: [] });
    monaco.editor.getModels()[0].setValue(this.state.scodeVulCode);
  };

  handleLineHightlight = (line) => {
    let correctLine = line
      .split(',')
      .sort(function (left, right) {
        return left - right;
      })
      ?.map(Number);

    for (let i = 0; i < correctLine.length; i++) {
      monaco.editor.getModels()[0].deltaDecorations(monaco.editor.getModels()[0].getAllDecorations(), [
        {
          range: {
            startLineNumber: correctLine[i],
            startColumn: 1,
            endLineNumber: correctLine[i],
            endColumn: 1,
          },
          options: {
            isWholeLine: true,
            inlineClassName: 'myDiffInlineDecoration', //글자색
            className: 'lineDecoration', //배경 하이라이팅
            glyphMarginClassName: 'myGlyphMarginClass', // 라인번호 앞 표시
            marginClassName: 'marginDecoration',
          },
        },
      ]);
    } //renderIndicators
  };

  handleDiff = () => {
    //toast.error('handleDiff');

    let correctLine = this.state.scodeLineNum
      .split(',')
      .sort(function (left, right) {
        return left - right;
      })
      ?.map(Number);

    //var originalModel = monaco.editor.createModel(this.state.code, "java");
    modifiedModel = monaco.editor.createModel(this.state.scodeSecCode, this.state.language);

    // 1단계에 생성된 에디터( monaco.editor.getModels()[0] )의 정답라인을 하이라이팅한다.
    for (let i = 0; i < correctLine.length; i++) {
      monaco.editor.getModels()[0].deltaDecorations(monaco.editor.getModels()[0].getAllDecorations(), [
        {
          range: {
            startLineNumber: correctLine[i],
            startColumn: 1,
            endLineNumber: correctLine[i],
            endColumn: 1,
          },
          options: {
            isWholeLine: true,
            inlineClassName: 'myDiffInlineDecoration', //글자색
            className: 'lineDecoration', //배경 하이라이팅
            glyphMarginClassName: 'myGlyphMarginClass', // 라인번호 앞 표시
            marginClassName: 'marginDecoration',
          },
        },
      ]);
    }

    // 2단계 에디터 생성
    var diffEditor = monaco.editor.createDiffEditor(document.getElementById('container'), this.state.diffOptions);
    diffEditor.setModel({
      original: monaco.editor.getModels()[0], //기존의 1단계 에디터를 original로 설정
      modified: modifiedModel, //scodeSecCode코드가 있는 에디터를 modified로 설정
    });
    test = diffEditor;
  };

  handleExit = () => {
    Alert({
      title: '진단훈련을 종료하시겠습니까?',
      handleClickAccept: () => {
        this.props.history.push('/student/securelist');
      },
      handleClickReject: () => {}, //reject
    });
  };

  handleSelectProblem = (e) => {
    monaco.editor.getModels()[0].setValue('');
    monaco.editor.getModels()[0].deltaDecorations([], []);

    document.getElementById('editor').style.display = 'block';
    document.getElementById('diffEditor').style.display = 'none';
    document.getElementById('diffEditor_compare').style.display = 'none';
    document.getElementById('diffEditor_compare1').style.display = 'none';

    const elReset = document.getElementById('fin');
    elReset.classList.remove('completeStep');

    this.setState({
      startTime: new Date(),
      wrongLineOpen: false,
      clickLine: [],
      step2Result: 0,
      scodeId: e.currentTarget.value.split(',')[0],
      problemNumber: e.currentTarget.value.split(',')[1],
      lineNumber: [],
    });

    // 2단계 모두 해결한 경우 정답을 출력할 수 있도록 하기 위해
    // 사용자의 문제 해결 정보를 먼저 가져 온 후 문제 정보를 가져오도록 변경

    const scodeId2 = e.currentTarget.value.split(',')[0];

    //단계 정보
    let form_step = new FormData();
    form_step.append('scodeId', scodeId2);
    form_step.append('userId', this.state.userId);

    let stepData;

    api({
      method: 'POST',
      url: `/users/sec/step`,
      data: form_step,
    }).then((response) => {
      stepData = response.data;
      api
        .get(`/users/sec/code/${scodeId2}/${this.state.userId}`)
        .then((response) => {
          this.setState({
            scodeId: response.data.scodeId,
            scodeNum: response.data.scodeNum,
            scodeVulFile: response.data.scodeVulFile,
            scodeVulCode: response.data.scodeVulCode,
            scodeVulDesc: response.data.scodeVulDesc,
            scodeSecFile: response.data.scodeSecFile,
            scodeSecDesc: response.data.scodeSecDesc,
            scodeSecCode: response.data.scodeSecCode,
            scodeLineNum: response.data.scodeLineNum,
            scodeSecLine: response.data.scodeSecLine,
            scodeGuideImg: response.data.scodeGuideImg,
            secIdIndex: this.state.secId,
            vulAnswerHintImg: response.data.vulAnswerHintImg,
            secAnswerHintImg: response.data.secAnswerHintImg,
            tryNumFirst: 0,
            tryNumSecond: 0,
          });

          if (stepData.solvedDateFirst != null) {
            // 1단계 풀이 완료한 경우
            if (test === null) {
              this.handleDiff();
            }
            this.handleResetStep1();
            this.handleResetStep2();
            test.getModifiedEditor().setValue(response.data.scodeSecCode);
            test.getOriginalEditor().setScrollTop(0); // 스크롤 맨 위로 이동
            monaco.editor.getModels()[0].setValue(response.data.scodeVulCode);
            this.handleLineHightlight(response.data.scodeLineNum);
            document.getElementById('editor').style.display = 'none';
            document.getElementById('diffEditor').style.display = 'block';
            this.setState({ step: 2 });
            if (stepData.solvedDateSecond != null) {
              this.setState({ step: 3 });
              // const el = document.getElementById("fin");
              // el.classList.add("completeStep");
            }
          } else {
            //step 1단계 풀이해야 하는 경우
            this.handleModalRetry();
            this.setState({ step: 1 });
          }
        })
        .catch((error) => {
          errorToast('잘못된 접근입니다. 다시 로그인 해주세요');
          // localStorage.removeItem('refreshToken');
          // localStorage.removeItem('token');
          // localStorage.removeItem('examYn');
          // window.location.href = '/';
        });
    });
  };

  handleModal2Finish = () => {
    this.setState({
      correctLineOpen: false,
      step: 3,
      options: {
        fontSize: '16.5px',
        readOnly: true, //true : 코드수정 불가 ,  false : 수정 가능
        automaticLayout: true, //true : 부모 div 크기에 맞춰서 자동으로 editor 크기 맞춰줌
        glyphMargin: true, //true: 체크 이미지 넣을 공간이 생김
        scrollBeyondLastLine: true, // true : 스크롤이 가능하게
      },
      diffOptions: {
        fontSize: '16.5px',
        readOnly: true,
        glyphMargin: true,
        renderIndicators: false, //eidtor 두개 비교해서 달라진 부분 +/- 가리킴
        scrollBeyondLastLine: true,
        automaticLayout: true,
      },
    });
  };

  handleModal2Retry = () => {
    this.setState({ wrongLineOpen: false });
  };

  handleResetStep1 = () => {
    this.setState({ lineNumber: [] });
    this.setState({ clickLine: [] });
    monaco.editor.getModels()[0].setValue(this.state.scodeVulCode);
    monaco.editor.getEditors()[0].setScrollTop(0); // 스크롤 맨 위로 이동
  };

  handleResetStep2 = () => {
    test.getModifiedEditor().setValue(this.state.scodeSecCode);
    test.getOriginalEditor().setScrollTop(0); // 스크롤 맨 위로 이동
  };

  render() {
    const { classes } = this.props;
    const { options, code } = this.state;

    return (
      <div className={classes.root} style={{ backgroundColor: '#252526' }}>
        {/* step 정답 */}
        <Dialog open={this.state.correctLineOpen} onClose={this.handleClose}>
          <DialogContent className='modalSize'>
            <div className='modalCorrect'>
              <img width='250px' height='auto' src={check_modal} alt='check' />
            </div>
            <div className='modalCorrect'>
              정답입니다.
              <br />
            </div>
            <div className='modalNext' style={{ fontSize: '1.4rem' }}>
              시도 횟수 : {this.state.step2Result === 0 ? this.state.tryNumFirst : this.state.tryNumSecond}번
            </div>
            <div className='modalNext'>
              <DialogActions>
                {this.state.step2Result === 0 ? (
                  <Button variant='contained' color='primary' onClick={this.handleModalNext_1}>
                    NEXT
                  </Button>
                ) : (
                  <Button variant='contained' color='primary' onClick={this.handleModal2Finish}>
                    닫기
                  </Button>
                )}
              </DialogActions>
            </div>
          </DialogContent>
        </Dialog>

        {/* step 실패 */}
        <Dialog open={this.state.wrongLineOpen} onClose={this.handleClose}>
          <DialogContent className='modalSize'>
            <div></div>
            <div className='modalCorrect'>
              <img width='250px' height='auto' src={error_modal} alt='error' />
            </div>
            {this.state.isError === 0 ? (
              <div className='modalCorrect'>
                틀렸습니다.
                <br />
              </div>
            ) : (
              <div className='modalCorrect'>
                컴파일 에러
                <br />
              </div>
            )}
            <div className='modalNext' style={{ fontSize: '1.4rem' }}>
              시도 횟수 : {this.state.step2Result === 0 ? this.state.tryNumFirst : this.state.tryNumSecond}번
            </div>
            <div className='modalNext'>
              <DialogActions>
                {this.state.step2Result === 0 ? (
                  <Button variant='contained' color='primary' onClick={this.handleModalRetry} className='reBtn'>
                    Retry
                  </Button>
                ) : (
                  <Button variant='contained' color='primary' onClick={this.handleModal2Retry} className='reBtn'>
                    Retry
                  </Button>
                )}
              </DialogActions>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.guideOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.guideHandleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
          classes={{ paper: classes.dialogPapaer }}
          fullWidth
          maxWidth='md'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            <GuideTitle>
              <i className='fas fa-book'></i>&nbsp;{'Guide'}
            </GuideTitle>
          </DialogTitle>
          <DialogContent>
            {this.state.secId && this.state.secIdIndex && this.state.scodeGuideImg && (
              <img
                src={`${process.env.REACT_APP_IP}/codeguide/${this.state.language}/${this.state.secIdIndex}.jpg`}
                width='100%'
                alt='scodeGuideImg'
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.guideHandleClose} color='primary' style={{ fontSize: '1.6rem', fontWeight: 'bold' }}>
              확인
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.hintOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.hinthandleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
          classes={{ paper: classes.dialogPapaer }}
          fullWidth
          maxWidth='md'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            <HintTitle>
              <i className='far fa-lightbulb'>&nbsp;</i>
              {'Hint'}
            </HintTitle>
          </DialogTitle>
          <DialogContent id='alert-dialog-slide-description'>
            <Text>{this.state.step === 1 ? this.state.scodeVulDesc : this.state.scodeSecDesc}</Text>
            {this.state.step === 1 && this.state.tryNumFirst > 2 ? (
              <>
                <h2>1단계 정답 힌트!</h2>
                <img
                  src={`${process.env.REACT_APP_IP}/answerImage/${this.state.language}/${this.state.secIdIndex}/${this.state.vulAnswerHintImg}`}
                  width='100%'
                  alt='answerHintImg'
                />
              </>
            ) : this.state.step === 2 && this.state.tryNumSecond > 2 ? (
              <>
                <h2>2단계 정답 힌트!</h2>
                <img
                  src={`${process.env.REACT_APP_IP}/answerImage/${this.state.language}/${this.state.secIdIndex}/${this.state.secAnswerHintImg}`}
                  width='100%'
                  alt='answerHintImg'
                />
              </>
            ) : (
              <></>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hinthandleClose} color='primary' style={{ fontSize: '1.6rem', fontWeight: 'bold' }}>
              확인
            </Button>
          </DialogActions>
        </Dialog>

        <CssBaseline />
        <AppBar
          position='fixed'
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar className={classes.toolbar} id='test2'>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={this.handleDrawerOpen}
              edge='start'
              className={clsx(classes.menuButton, this.state.open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <div className='secotext' style={{ color: 'aqua', fontWeight: 'bolder' }}>
              진단대상코드
            </div>
            {this.state.step === 1 ? null : (
              <div className='secotext step2Title' style={{ color: '#e79602', fontWeight: 'bolder' }}>
                수정대상코드
              </div>
            )}

            <LightTooltip title='Close' arrow>
              <ColorButton id='exit' variant='contained' color='primary' onClick={this.handleExit}>
                <p className='secotext secowhite'>
                  <i className='fas fa-times'></i>
                </p>
              </ColorButton>
            </LightTooltip>
          </Toolbar>
        </AppBar>
        {/*서랍식으로 학습창 구현 */}
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='left'
          open={this.state.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <div className='secureTitle'>
              <img
                src='/assets/images/logo-secolab_sub.png'
                alt='SECOLAB Logo'
                style={{ width: '120px', opacity: '0.7', marginLeft: '5px' }}
              />{' '}
            </div>
            <div className='closeLeftSide' onClick={this.handleDrawerClose}>
              <i className='fas fa-angle-double-left'></i>
            </div>
          </div>

          <div style={{ height: '100%', width: '100%', backgroundColor: '#252526' }}>
            <div className='selectTitle'>
              <p>
                <b>
                  <i className='fas fa-file-code'></i>&nbsp;&nbsp;&nbsp;{this.state.language}&nbsp;&nbsp;/&nbsp;&nbsp;
                  {this.state.secId}
                </b>
              </p>
            </div>
            <div className='selectDiv'></div>
            <ScategorySelect
              language={this.state.language}
              languageId={this.state.languageId}
              scategoryId={this.state.scategoryId}
              secId={this.state.secId}
              bringScategoryId={this.bringScategoryId}
              bringSecId={this.bringSecId}
              secName={this.state.secName}
              lists={this.state.lists}
              problemNumber={this.state.problemNumber}
              handleSelectProblem={this.handleSelectProblem}
            />
            <div className='stepDiv'></div>
            <div className='stepDiv2'>
              <CodeStepper step={this.state.step} />
            </div>
          </div>
        </Drawer>

        <main
          id='fin'
          className={clsx(classes.content, {
            [classes.contentShift]: this.state.open,
          })}
          style={{ zIndex: '1000' }}
        >
          <div style={{ width: `getel` }}>
            <div id='editor' className={'eidtorSize'} style={{ display: 'block' }}>
              <MonacoEditor
                language={this.state.language}
                theme='vs-dark'
                options={options}
                value={this.state.problemNumber > 0 ? this.state.scodeVulCode + ' ' : this.chkLan(this.state.language)}
                editorDidMount={this.editorDidMount}
                width={this.state.width}
              />
            </div>
          </div>

          <div id='diffEditor' style={{ display: 'block' }}>
            <div id='container' className={'eidtorSize'}></div>
          </div>
          <div id='diffEditor_compare' />
          <div id='diffEditor_compare1' />

          <Toolbar id='test' className={classes.toolbar1} style={{ position: 'fixed', bottom: '0', right: '0' }}>
            {/* <ColorButton id="submit" color="primary" variant="contained" className="submit" onClick={this.handleSubmitStep1} ><p className="secotext secowhite">제출</p></ColorButton> */}

            {this.state.problemNumber > 0 ? (
              <Fragment>
                {this.state.step === 1 ? (
                  <Fragment>
                    <ColorButton
                      id='guide'
                      color='primary'
                      variant='contained'
                      className='guide'
                      onClick={this.guideHandleOpen}
                    >
                      <p className='secotext secowhite'>
                        <i className='fas fa-question-circle'></i>&nbsp;guide
                      </p>
                    </ColorButton>
                    <ColorButton
                      id='hint'
                      color='primary'
                      variant='contained'
                      className='hint'
                      onClick={this.hintHandleOpen}
                    >
                      <p className='secotext secowhite'>
                        <i className='fas fa-question-circle'></i>&nbsp;hint
                      </p>
                    </ColorButton>
                    <ColorButton
                      id='reset'
                      color='primary'
                      variant='contained'
                      className='reset'
                      onClick={this.handleResetStep1}
                    >
                      <p className='secotext secowhite'>초기화</p>
                    </ColorButton>
                    <ColorButton
                      id='submit'
                      color='primary'
                      variant='contained'
                      className='submit'
                      onClick={this.handleSubmitStep1}
                    >
                      <p className='secotext secowhite'>제출</p>
                    </ColorButton>
                  </Fragment>
                ) : this.state.step === 2 ? (
                  <Fragment>
                    <ColorButton
                      id='guide'
                      color='primary'
                      variant='contained'
                      className='guide'
                      onClick={this.guideHandleOpen}
                    >
                      <p className='secotext secowhite'>
                        <i className='fas fa-question-circle'></i>&nbsp;guide
                      </p>
                    </ColorButton>
                    <ColorButton
                      id='hint'
                      color='primary'
                      variant='contained'
                      className='hint'
                      onClick={this.hintHandleOpen}
                    >
                      <p className='secotext secowhite'>
                        <i className='fas fa-question-circle'></i>&nbsp;hint
                      </p>
                    </ColorButton>
                    <ColorButton id='reset' variant='contained' className='reset' onClick={this.handleResetStep2}>
                      <p className='secotext secowhite'>초기화</p>
                    </ColorButton>
                    <ColorButton id='submit' variant='contained' className='submit' onClick={this.handleSubmitStep2}>
                      <p className='secotext secowhite'>제출</p>
                    </ColorButton>
                  </Fragment>
                ) : (
                  ''
                )}
              </Fragment>
            ) : (
              ''
            )}
          </Toolbar>
        </main>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(SecCategory));
