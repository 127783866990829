import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import './coursestyle.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  tab: {
    backgroundColor: 'white',
    color: 'gray',
    borderRight: 'thin solid #e7e7e7',
  },
  indicator: {
    backgroundColor: '#007bff',
  },
}));

export default ({ instData = [], introData = [] }) => {
  const classes = useStyles();
  const [value, setValue] = useState('one');

  var indi01 = [];
  var indi02 = [];

  if (introData.introDiffCnts1 != null) {
    indi01 = introData.introDiffCnts1.split('\\n');
  }
  if (introData.introDiffCnts2 != null) {
    indi02 = introData.introDiffCnts2.split('\\n');
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='mypage'>
      <div className='introduce'>
        <AppBar position='static'>
          <Tabs
            className={classes.tab}
            classes={{ indicator: classes.indicator }}
            value={value}
            onChange={handleChange}
            aria-label='wrapped label tabs example'
          >
            <Tab value='one' label={<p className='secosub1'>과정 소개</p>} wrapped {...a11yProps('one')} />
            <Tab wrapped value='two' label={<p className='secosub1'>강사 소개</p>} {...a11yProps('two')} />
          </Tabs>
        </AppBar>
      </div>
      <TabPanel value={value} index='one'>
        <div className='detailContainer'>
          <div className='row'>
            <div className='col-md-12'>
              <div
                className='row detailcontentBox'
                style={{ borderRadius: '5px 5px 0 0', backgroundColor: 'gray', color: 'white', border: 'none' }}
              >
                <div className='col-md-12 detailRight'>
                  <p className='secosub1 secowhite'>{introData.introTitle}</p>
                </div>
              </div>
              <div className='detailcontentBox' style={{ padding: '0.5vw 0', marginTop: '1vw' }}>
                <div className='detailRight'>
                  <p className='secosub2 secoblue'>{introData.introTitleCnts}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row detailcontentBox'>
                <div className='col-md-2 detailLeft'>
                  <p className='secosub2 secodgray'>학습 목표</p>
                </div>
                <div className='col-md-10 detailRight'>
                  <p className='secotext secogray'>{introData.introObj1}</p>
                  <p className='secotext secogray'>{introData.introObj2}</p>
                  <p className='secotext secogray'>{introData.introObj3}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='row detailcontentBox introDetail'>
                <div className='col-md-12 detailRight'>
                  <p className='secosub2 secoblue'>{introData.introDetail}</p>
                </div>
              </div>
            </div>
          </div>
          {introData.introDiff1 && (
            <div className='row'>
              <div className='col-md-12'>
                <div
                  className='row detailcontentBox'
                  style={{ backgroundColor: '#f5f5f5', padding: '0.5vw 0', marginTop: '1vw' }}
                >
                  <div className='col-md-12 detailLeft'>
                    <p className='secosub2 secodgray'>{introData.introDiff1}</p>
                  </div>
                </div>
                <div className='row detailcontentBox'>
                  <div className='col-md-12 detailRight'>
                    <p className='secotext secogray' style={{ lineHeight: '150%' }}>
                      {indi01[0]}
                    </p>
                    <p className='secotext secogray' style={{ lineHeight: '150%' }}>
                      {indi01[1]}
                    </p>
                    <p className='secotext secogray' style={{ lineHeight: '150%' }}>
                      {indi01[2]}
                    </p>
                    <p className='secotext secogray' style={{ lineHeight: '150%' }}>
                      {indi01[3]}
                    </p>
                    <p className='secotext secogray' style={{ lineHeight: '150%' }}>
                      {indi01[4]}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {introData.introDiff2 && (
            <div className='row'>
              <div className='col-md-12'>
                <div
                  className='row detailcontentBox'
                  style={{ backgroundColor: '#f5f5f5', padding: '0.5vw 0', marginTop: '1vw' }}
                >
                  <div className='col-md-12 detailLeft'>
                    <h4 className='secosub2 secodgray'>{introData.introDiff2}</h4>
                  </div>
                </div>
                <div className='row detailcontentBox'>
                  <div className='col-md-12 detailRight'>
                    <p className='secotext secogray' style={{ lineHeight: '150%' }}>
                      {indi02[0]}
                    </p>
                    <p className='secotext secogray' style={{ lineHeight: '150%' }}>
                      {indi02[1]}
                    </p>
                    <p className='secotext secogray' style={{ lineHeight: '150%' }}>
                      {indi02[2]}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {introData.introDiff3 && (
            <div className='row'>
              <div className='col-md-12'>
                <div
                  className='row detailcontentBox'
                  style={{ backgroundColor: '#f5f5f5', padding: '0.5vw 0', marginTop: '1vw' }}
                >
                  <div className='col-md-12 detailLeft'>
                    <h4 className='secosub2 secodgray'>{introData.introDiff3}</h4>
                  </div>
                </div>
                <div className='row detailcontentBox'>
                  <div className='col-md-12 detailRight'>
                    <p className='secotext secogray'>{introData.introDiffCnts3}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index='two'>
        <div className='detailContainer'>
          <div className='row'>
            <div className='col-md-12'>
              <div
                className='row detailcontentBox'
                style={{ borderRadius: '5px 5px 0 0', backgroundColor: 'gray', color: 'white', border: 'none' }}
              >
                <div className='col-md-12 detailRight'>
                  <p className='secosub1 secowhite'>{instData.instIntro}</p>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='row detailcontentBox' style={{ border: 'none', alignItems: 'center' }}>
                <div className='col-md-3 detailLeft instDetail01'>
                  <img src={instData.instImage} alt='instImage' />
                </div>
                <div className='col-md-9 detailRight instDetail02' style={{ margin: '1vw 0' }}>
                  <p className='secotext secogray' style={{ lineHeight: '150%' }}>
                    이름 : {instData.instName}
                  </p>
                  <p className='secotext secogray' style={{ lineHeight: '150%' }}>
                    {instData.instAgency} / {instData.instPosition}
                  </p>
                  <p className='secotext secogray' style={{ lineHeight: '150%' }}>
                    전문분야 : {instData.instField}
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='row detailcontentBox' style={{ border: 'none' }}>
                    <div className='col-md-12 detailLeft instDetail03'>
                      <p className='secotext secogray'>
                        {(instData.instCnts || '').split('\\n')?.map((line, index) => {
                          return (
                            <Fragment key={index}>
                              {line}
                              <br></br>
                            </Fragment>
                          );
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
    </div>
  );
};
