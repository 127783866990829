import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { FormControl, TextField, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { api } from 'api/config';
import { AuthAtom } from 'state';
import { errorToast } from 'utilities/toast';
import Loading from '../../../../../components/Loading';
import './style.scss';

const useStyles = makeStyles(() => ({
  formControl: {},
  selectEmpty: {
    marginTop: '7px',
  },
}));

function CreateProject(props) {
  const { userId } = useRecoilValue(AuthAtom);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(!loading);
    }, 300);
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    const questId = props.questId;
    const questOriginName = props.questName;
    const questLanguageType = props.questLanguageType;
    api
      .post(`/users/quests/sampleprojects`, {
        userId,
        questId,
        questOriginName,
        questLanguageType,
      })
      .then((response) => {
        api
          .post(`/users/quests/containers`, {
            questId,
          })
          .then((response) => {
            const { data } = response;
            if (data === 'success') {
              props.setActive(2);
              props.history.push('/student/codediagnosis/listprojects');
            } else {
              errorToast('컨테이너 오류 발생하니 다시 시도 해주세요.');
            }
          })
          .catch((error) => {
            errorToast('컨테이너 오류 발생하니 다시 시도 해주세요.');
          });
      })
      .catch((error) => {
        errorToast('프로젝트 생성 오류 발생하니 다시 시도 해주세요.');
      });
  };

  if (loading) {
    return <Loading type='spinningBubbles' color='#2F96EB' />;
  }
  return (
    <div className='wrapper-create-project'>
      <div className='create-project'>
        <span className='close' onClick={() => props.closeModal(false)}>
          <i className='fas fa-times'></i>
        </span>
        <div className='create-project__container'>
          <p style={{ textAlign: 'center', fontSize: '30px', fontWeight: '700', marginBottom: '10px' }}>
            프로젝트 정보
          </p>
          <div className='project-name col'>
            <div className='form-label'>프로젝트 이름</div>
            <div className='form-content'>
              <TextField
                id='outlined-textarea'
                label='이름'
                value={props.questName}
                variant='outlined'
                style={{ width: '100%' }}
                disabled
              />
            </div>
          </div>
          <div className='project-type col' style={{ marginTop: '-20px' }}>
            <div className='form-label'>프로젝트 타입</div>
            <div className='form-content'>
              <FormControl variant='outlined' className={classes.formControl} style={{ width: '100%' }}>
                <TextField
                  id='outlined-textarea'
                  label='프로젝트 타입'
                  value={props.questLanguageType}
                  disabled
                  color='secondary'
                  variant='outlined'
                  style={{ width: '100%' }}
                />
              </FormControl>
            </div>
          </div>
          <Box textAlign='center'>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              // className={classes.submit}
              style={{
                width: '20%',
                minWidth: '150px',
                borderRadius: '50px',
                padding: '10px 0',
                margin: '15px 0',
              }}
              onClick={() => handleSubmit()}
            >
              프로젝트 생성
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default CreateProject;
