import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

import Loading from '../../../../../components/Loading';
import { api } from 'api/config';
import './style.scss';
import { AuthAtom } from 'state';
import { errorToast } from 'utilities/toast';

const useStyles = makeStyles(() => ({
  formControl: {
    // margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: '7px',
  },
}));

function CreateProject(props) {
  const classes = useStyles();
  const { userId } = useRecoilValue(AuthAtom);

  const [projectType, setProjectType] = useState('');
  const [projectName, setProjectName] = useState(props.projectName);
  const [modalSpring, setModalSpring] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(!loading);
    }, 300);
  }, []);

  const handleChange = (event) => {
    setProjectType(event.target.value);
    event.target.value === 'java' ? setModalSpring(true) : setModalSpring(false);
  };

  const handleSubmit = () => {
    if (projectName.length >= 15 || !projectType) {
      projectName.length >= 15
        ? errorToast('프로젝트 길이 15길이 제한됩니다.')
        : errorToast('프로젝트 타입을 선택하세요.');
      return;
    } else {
      // const url = `${process.env.REACT_APP_IP}/users/checkExistsProjectName/${projectName}`;
      // api.get(url, {
      //     headers: {
      //         'AccessToken': `Bearer ${token}`
      //     }
      // })
      // .then(response => {
      // if(response.data === "exists"){
      //   alert("이름 중복됩니다")
      // }else{
      if (props.sampleName === '') {
        api
          .post(`/users/projects`, {
            // projectUserId: userId,
            projectName,
            projectType,
          })
          .then(() => {
            props.closeModal(false);
          })
          .catch(() => {});
      } else {
        const sampleName = props.sampleName;
        api
          .post(`/users/sampleprojects`, {
            // projectUserId: userId,
            sampleName,
            projectName,
            projectType,
          })
          .then((response) => {
            const projectId = response.data;
            props.closeModal(false);

            api
              .post(`/users/containers`, {
                projectId,
              })
              .then((response) => {
                const { data } = response;
                if (data === 'success') {
                  //props.handleUpdateContainer();

                  api
                    .get(`/users/editor/${projectId}`)
                    .then((response) => {
                      const { data } = response;
                      // window.open(
                      //     `${process.IP}:${data.vscode_port}/?folder=/home/coder/projects/`,
                      //     '_blank'
                      // );
                      props.history.push('/student/codediagnosis/listprojects');
                      if (data !== null) {
                        setTimeout(() => {
                          window.open(
                            `${process.env.REACT_APP_HTTP_IP}:${data.vscode_port}/?folder=/home/coder/projects/`,
                            '_blank'
                          );
                        }, 3000);
                      } else {
                        errorToast('에디터 서버 접근 실패합니다. 다시 확인해주세요');
                      }
                    })
                    .catch(() => {});
                } else {
                  errorToast('컨테이너 재시작 오류 발생하니 다시 시도 해주세요1.');
                }
              })
              .catch(() => {
                errorToast('컨테이너 재시작 오류 발생하니 다시 시도 해주세요2.');
              });
          })
          .catch(() => {});
      }
      // }
      // })
      // .catch(error => {});
    }
  };

  const handleSubmitSpring = (data) => {
    const {
      selectProject,
      selectLanguage,
      selectBoot,
      inputGroupId,
      inputArtifactId,
      inputDesc,
      inputPackageName,
      dependencies,
    } = data;

    if (projectName.length >= 15) {
      projectName.length >= 15
        ? errorToast('프로젝트 길이 15길이 제한됩니다.')
        : errorToast('프로젝트 타입을 선택하세요.');
      return;
    } else {
      // const url = `${process.env.REACT_APP_IP}/users/checkExistsProjectName/${userId}/${projectName}`;
      // api.get(url, {
      //   headers: {
      //     'AccessToken': `Bearer ${token}`
      //   }
      //   })
      //   .then(response => {
      //     if(response.data === "exists"){
      //       alert("이름 중복됩니다")
      //     }else{
      //         props.closeModal(false)
      // const { data } = response;
      api
        .post(`/users/springprojects`, {
          projectUserId: userId,
          projectName,
          projectType,
          projectBuildType: selectProject,
          projectLanguage: selectLanguage,
          projectSpringBootVer: selectBoot,
          projectMetaGroupId: inputGroupId,
          projectMetaArtifactId: inputArtifactId,
          projectMetaDesc: inputDesc,
          projectMetaPackage: inputPackageName,
          projectDependencies: dependencies,
        })
        .then(() => {
          props.closeModal(false);
        })
        .catch(() => {});
      // }

      // })
      // .catch(error => {});
    }
  };

  if (loading) {
    return <Loading type='spinningBubbles' color='#2F96EB' />;
  }
  return (
    <div className='wrapper-create-project'>
      <div className='create-project'>
        <span className='close' onClick={() => props.closeModal(false)}>
          <i className='fas fa-times'></i>
        </span>
        <div className='create-project__container'>
          <p style={{ textAlign: 'center', fontSize: '30px', fontWeight: '700', marginBottom: '10px' }}>
            프로젝트 정보
          </p>
          <div className='project-name col'>
            <div className='form-label'>프로젝트 이름</div>
            <div className='form-content'>
              <TextField
                id='outlined-textarea'
                label='이름'
                placeholder='프로젝트 이름을 입력하세요. ( 최대 15글자 )'
                value={projectName}
                multiline
                variant='outlined'
                style={{ width: '100%' }}
                onChange={(e) => setProjectName(e.target.value)}
              />
            </div>
          </div>
          <div className='project-type col' style={{ marginTop: '-20px' }}>
            <div className='form-label'>프로젝트 타입</div>
            <div className='form-content'>
              <FormControl variant='outlined' className={classes.formControl} style={{ width: '100%' }}>
                <InputLabel id='demo-simple-select-outlined-label'>프로젝트 타입</InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  value={projectType}
                  onChange={handleChange}
                  label='project-type'
                >
                  <MenuItem value='java'>Java Spring</MenuItem>
                  <MenuItem value='node'>Nodejs</MenuItem>
                  <MenuItem value='python'>Python</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
            {modalSpring ? (
              <ProjectSpring
                project='Maven Project'
                language='Java'
                boot=''
                projectGroup=''
                desc=''
                packageName=''
                handleSubmitSpring={handleSubmitSpring}
              />
            ) : (
              <Box textAlign='center'>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  // className={classes.submit}
                  style={{
                    width: '20%',
                    minWidth: '150px',
                    borderRadius: '50px',
                    padding: '10px 0',
                    margin: '15px 0',
                  }}
                  onClick={() => handleSubmit()}
                >
                  프로젝트 생성
                </Button>
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function ProjectSpring(props) {
  const { handleSubmitSpring } = props;

  const [selectProject, setSelectProject] = useState('Maven');
  const [selectLanguage, setLanguageProject] = useState('Java');
  const [selectBoot, setBootProject] = useState('2.4.0');
  const [inputGroupId, setInputGroupId] = useState('');
  const [inputArtifactId, setInputArtifactId] = useState('');
  const [inputDesc, setInputDesc] = useState('');
  const [inputPackageName, setInputPackage] = useState('');
  const [dependencies, setDependencies] = useState('web');

  return (
    <WrapperForSpring>
      <div>
        <div className='container-left'>
          <div style={{ display: 'flex' }}>
            <div className='project' style={{ flex: '1' }}>
              <FormControl component='fieldset'>
                <FormLabel component='legend'>Project</FormLabel>
                <RadioGroup
                  aria-label='project'
                  name='project'
                  value={selectProject}
                  onChange={(e) => setSelectProject(e.target.value)}
                >
                  <FormControlLabel value='Maven' control={<Radio />} label='Maven' />
                  <FormControlLabel value='Gradle' control={<Radio />} label='Gradle' />
                </RadioGroup>
              </FormControl>
            </div>
            <div className='language' style={{ flex: '1' }}>
              <FormControl component='fieldset'>
                <FormLabel component='legend'>Language</FormLabel>
                <RadioGroup
                  aria-label='language'
                  name='language'
                  value={selectLanguage}
                  onChange={() => setLanguageProject()}
                >
                  <FormControlLabel value='Java' control={<Radio />} label='Java' />
                  <FormControlLabel value='koltin' control={<Radio />} label='Koltin' />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className='spring-boot'>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Spring Boot</FormLabel>
              <RadioGroup
                aria-label='spring-boot'
                name='spring-boot'
                value={selectBoot}
                onChange={(e) => setBootProject(e.target.value)}
                style={{ flexDirection: 'row' }}
              >
                <FormControlLabel value='2.4.0' control={<Radio />} label='2.4.0' />
                <FormControlLabel value='2.3.4' control={<Radio />} label='2.3.4' />
                <FormControlLabel value='2.2.10' control={<Radio />} label='2.2.10' />
                <FormControlLabel value='2.1.17' control={<Radio />} label='2.2.17' />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='project-info'>
            <FormControl component='fieldset' style={{ width: '100%' }}>
              <FormLabel component='legend'>Project Metadata</FormLabel>
              <div className='project-info__label-group info-col'>
                <div>Group</div>
                <div>
                  <TextField
                    value={inputGroupId}
                    id='standard-full-width'
                    style={{ margin: 8 }}
                    placeholder='Group'
                    fullWidth
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setInputGroupId(e.target.value)}
                  />
                </div>
              </div>
              <div className='project-info__label-artifact info-col'>
                <div>Artifact</div>
                <div>
                  <TextField
                    value={inputArtifactId}
                    id='standard-full-width'
                    style={{ margin: 8 }}
                    placeholder='Artifact'
                    fullWidth
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setInputArtifactId(e.target.value)}
                  />
                </div>
              </div>

              <div className='project-info__label-desc info-col'>
                <div>Description</div>
                <div>
                  <TextField
                    value={inputDesc}
                    id='standard-full-width'
                    style={{ margin: 8 }}
                    placeholder='Description'
                    fullWidth
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setInputDesc(e.target.value)}
                  />
                </div>
              </div>
              <div className='project-info__label-package info-col'>
                <div>Package name</div>
                <div>
                  <TextField
                    value={inputPackageName}
                    id='standard-full-width'
                    style={{ margin: 8 }}
                    placeholder='Package name'
                    fullWidth
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setInputPackage(e.target.value)}
                  />
                </div>
              </div>
            </FormControl>
          </div>
        </div>
        <div className='container-right'>
          <FormControl component='fieldset' style={{ width: '100%' }}>
            <FormLabel component='legend'>Dependencies</FormLabel>
            <RadioGroup
              aria-label='spring-boot'
              name='spring-boot'
              value={dependencies}
              onChange={(e) => setDependencies(e.target.value)}
              style={{ flexDirection: 'row' }}
            >
              <FormControlLabel value='web' control={<Radio />} label='Web' />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <Box textAlign='center'>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          // className={classes.submit}
          style={{ width: '20%', minWidth: '200px' }}
          onClick={() =>
            handleSubmitSpring({
              selectProject,
              selectLanguage,
              selectBoot,
              inputGroupId,
              inputArtifactId,
              inputDesc,
              inputPackageName,
              dependencies,
            })
          }
        >
          프로젝트 생성
        </Button>
      </Box>
    </WrapperForSpring>
  );
}

const WrapperForSpring = styled.div`
  > div {
    display: flex;
    justify-content: center;
    width: 100%;

    .container-left {
      width: 50%;

      > div {
        margin-bottom: 20px;
      }

      .project-info .info-col {
        display: flex;

        div:nth-child(1) {
          flex: 1;
          text-align: end;
          /* padding-right: 20px; */
          justify-content: flex-end;
          display: flex;
          align-items: center;
          font-size: 14px;
        }

        div:nth-child(2) {
          flex: 3;
        }
      }
    }

    .container-right {
      width: 50%;
    }
  }
`;

export default CreateProject;
