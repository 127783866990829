import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#919191',
    color: '#fff',
    fontSize: 18,
    maxWidth: '120px',
    minWidth: '120px',
    lineHeight: '1.1',
    wordBreak: 'break-word',
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);
const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#eee',
    },
  },
}))(TableRow);

const TableView = ({ studentCounts = [] }) => {
  const tableToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.table_to_sheet(document.getElementById('table-to-print'), {
      raw: true,
    });

    XLSX.utils.book_append_sheet(wb, ws1, '시큐어코딩inC 수강생');

    ws1['!cols'].push({ width: 15 }, { width: 20 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 10 });

    XLSX.writeFile(wb, `SECOLAB_C언어_수강생.xlsx`);
  };

  return (
    <TableBox component={Paper} className='content'>
      <TableContainer>
        <Table size='medium' id='table-to-print' aria-label='customized table'>
          <TableHead rowcount={10}>
            <TableRow>
              <StyledTableCell align='center'>이름</StyledTableCell>
              <StyledTableCell align='center'>ID</StyledTableCell>
              <StyledTableCell align='center'>신청일자</StyledTableCell>
              <StyledTableCell align='center'>학습시작일자</StyledTableCell>
              <StyledTableCell align='center'>학습종료일자</StyledTableCell>
              <StyledTableCell align='center'>학습진도율</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentCounts?.length !== 0 &&
              studentCounts?.map((item, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell align='center'>{item.name}</StyledTableCell>
                    <StyledTableCell align='center'>{item.userId}</StyledTableCell>
                    <StyledTableCell align='center'>{item.applicationDate}</StyledTableCell>
                    <StyledTableCell align='center'>{item.groupStartDate}</StyledTableCell>
                    <StyledTableCell align='center'>{item.groupDueDate}</StyledTableCell>
                    <StyledTableCell align='center'>{item.trainProgressAvg}%</StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {studentCounts && studentCounts?.length === 0 && <NoResult />}

      {studentCounts && studentCounts?.length !== 0 && (
        <button onClick={() => tableToExcel()} className='excelBtn'>
          <i className='far fa-file-excel'></i> Excel 다운로드
        </button>
      )}
    </TableBox>
  );
};

const NoResult = () => (
  <NoResultBox>
    <p>해당 월에 수강자가 존재하지 않습니다.</p>
  </NoResultBox>
);

const TableBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;

  > button {
    margin-top: 20px;
  }
`;

const NoResultBox = styled.div`
  width: 100%;
  text-align: center;
  margin: 40px 0;

  p {
    color: #919191;
    background: #fff;
    margin: 60px 0 30px;
    font-size: 18px;
    font-weight: bold;
  }
`;

export default TableView;
